import {assign, createMachine} from "xstate";
import apiMethods from "@/js/api/apiMethods";

export const salePointEditMachine = createMachine({
    id: "salePointEditMachine",
    context: {
      point: {},
      pointId: null,
      name: null,
      entity_name: null,
      region: null,
      address: null,
      owner: null,
      status: null,
      message: null,
      errorMessage: []
    },
    initial: "init",
    states: {
      init: {
        on: {
          INPUT_STATUS: {
            actions: ["saveStatus"],
            internal: true,
          },
          SET_POINT_ID: {
            actions: ["savePointId"]
          },
          GET_POINT: {
            target: "getPointInfo"
          }
        },
      },
      getPointInfo: {
        invoke: {
          id: "getPoint",
          src: "getPoint",
        },
        on: {
          DONE: {
            actions: ["savePoint"],
          },
          INPUT_STATUS: {
            actions: ['saveStatus']
          },
          ERROR: {
            target: "init",
            actions: ["saveError"],
          },
          SUBMIT_POINT: {
            target: "saveEditSalePoint",
            actions: ["clearError"],
          },
        },
      },
      saveEditSalePoint: {
        invoke: {
          id: "saveEditPoint",
          src: "saveEditPoint",
        },
        on: {
          DONE: {
            actions: ["reset", "saveMessage"],
          },
          ERROR: {
            target: "init",
            actions: ["saveError"],
          },
          GET_POINT: {
            target: "init"
          }
        },
      },
    },
  },
  {
    actions: {
      savePoint: assign({
        point: (ctx, message) => message.data
      }),
      savePointId: assign({
        pointId: (ctx, message) => message.data
      }),
      saveMessage: assign({
        message: (ctx, message) => message.data
      }),
      saveStatus: assign({
        status: (ctx, message) => {
          return message.data
        }
      }),
      saveError: assign({
        errorMessage: (ctx, message) => ctx.errorMessage.concat(message.data),
      }),
      clearError: assign({
        errorMessage: [],
      }),
      reset: assign({
        point: {},
        name: null,
        entity_name: null,
        region: null,
        address: null,
        owner: null,
        status: null,
        errorMessage: []
      }),
      scrollTop: () => window.scrollTo(0, 0),
    },
    services: {
      saveEditPoint: (ctx, message) => async (send) => {
        const errors = {
          statusError: "",
        };

        const fields = {
          status: ctx.status,
        };

        const fieldKeys = Object.keys(fields);
        fieldKeys.forEach((key) => {
          if (!fields[key]) {
            errors[`${key}Error`] = "Поле является обязательным для заполнения";
          }
        });

        const haveErrors = Object.values(errors).some((el) => el !== "");

        if (haveErrors) {
          send({
            type: "ERROR",
            data: errors,
          });
        } else {
          const FD = new FormData();
          
          fieldKeys.forEach((key) => {
            if (!['point', 'errorMessage'].includes(key)) {
              FD.append(key, fields[key]);
            }
          });
          const response =
            await apiMethods.salePoints.editSalePoint(ctx.point.id, FD);

          if (response.status === 200) {
            send({
              type: "DONE",
              data: response.data.message
            });
          } else {
            send({
              type: "ERROR",
              data: {
                serverError: response.data.message,
              },
            });
          }
        }
      },
      getPoint: (ctx, message) => async (send) => {
        const response = await apiMethods.salePoints.getPointInfo(ctx.pointId)
        if (response.status === 200) {
          send({
            type: "INPUT_STATUS",
            key: 'status',
            data: response.data.data?.status.slug
          })
          send({
            type: "DONE",
            data: response.data.data
          })
        } else {
          send({
            type: "ERROR",
            serverError: response.data.message,
          })
        }
      }
    },
  },
);