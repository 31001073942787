import clsx from "clsx";

export function ChartSelect(props) {
  const changeParameter = function (event) {
    const data = props.multiple ? Array.from(event.target.selectedOptions, (item) => item.value) : event.target.value

    props.send({
      type: props.type,
      data: {
        [props.dataName]: data,
      },
    });
  };

  return (
    <div className={"customSelect"}>
      <label
        htmlFor={props.name}
        className={clsx({ ["visuallyHidden"]: true })}
      >
        {props.label}
      </label>
      <div className="selectContainer">
        <select
          name={props.name}
          id={props.name}
          value={props.value}
          multiple={props.multiple}
          onChange={(event) => changeParameter(event)}
          defaultValue={props.default}
        >
          {props.default === "" && <option value="">Все</option>}

          {props.options?.map((item, idx) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
