import { useEffect } from "react";
import styles from "./FeedbackForm.module.scss";
import clsx from "clsx";
import {Loader} from "../Loader/Loader";
import {useMachine, useActor} from "@xstate/react";
import {feedbackMachine} from "../../xstate/feedback.machine";
import { AuthState } from "../../xstate/auth.machine";


export function FeedbackForm() {
    const [stateFeedback, sendFeedback] = useMachine(feedbackMachine);
    const [stateAuth, sendAuth] = useActor(AuthState);

    useEffect(() => {
        if (stateAuth.context?.email && stateAuth.context?.id) {
            sendFeedback({
                type: "INPUT_EMAIL",
                key: 'email',
                data: stateAuth.context.email
            })
        }
        
    }, [])

    return (
        <section className={clsx(styles.feedbackForm)}>
            <div className={styles.wrapper}>
                {
                    stateFeedback.matches("send") && <div className={"fullPageLoader"}><Loader/></div>
                }

                <h1>Обратная связь</h1>
                <p>Мы ценим ваше мнение. Свои вопросы, пожелания и комментарии вы можете направить нам, заполнив форму
                    ниже.</p>
                {
                    stateFeedback.matches("success")
                        ? <div className={styles.successMessage}>
                            <p className={"h2"}>Ваше обращение отправлено</p>
                            <button
                                type="button"
                                className={"btn btnPrimary"}
                                onClick={() => sendFeedback({type: "ONE_MORE"})}
                            >
                                Отправить еще одно
                            </button>
                        </div>
                        : <form onSubmit={
                            (event) => {
                                event.preventDefault();
                                sendFeedback({type: "SUBMIT"})
                            }
                        }
                        >
                            <div className="formRow">
                                <label htmlFor="email">
                                    Ваш e-mail
                                </label>
                                <input
                                    id="email"
                                    type="email"
                                    value={stateFeedback.context.email}
                                    name="email"
                                    onChange={(event) => {
                                        sendFeedback({
                                            type: "INPUT_EMAIL",
                                            key: 'email',
                                            data: event.target.value
                                        })

                                    }}
                                />
                                {stateAuth.context?.email && stateAuth.context?.id ? 
                                    <div className={styles.helperText}>Вы можете изменить email, если хотите получить ответ на другую почту</div>
                                    : <></>
                                }
                                
                                {
                                    stateFeedback.context.errorMessage.map(item=> {
                                        return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                                            {item.emailError}
                                        </div>
                                    })
                                }
                            </div>
                            <div className="formRow">
                                <label htmlFor="message">Ваше сообщение</label>
                                <textarea
                                    name="message"
                                    id="message"
                                    onChange={(event) => {
                                        sendFeedback({
                                            type: "INPUT_MESSAGE",
                                            key: 'message',
                                            data: event.target.value
                                        })

                                    }}
                                />
                                {
                                    stateFeedback.context.errorMessage.map(item=> {
                                        return item.messageError !== "" && <div className={"errorMessage"} key={"errMsg"}>
                                            {item.messageError}
                                        </div>
                                    })
                                }
                            </div>
                            <div className="forRow">
                                <label htmlFor="images">Добавить фото или видео</label>
                                <div className={styles.imagesContainer}>
                                    <div className={styles.imageInput}>
                                        <input
                                            name={"images"}
                                            id={"images"}
                                            type="file"
                                            accept="image/*,video/*"
                                            multiple
                                            onChange={(event) => {
                                                sendFeedback({
                                                    type: "INPUT_FILES",
                                                    data: event.target.files
                                                })

                                            }}
                                        />
                                    </div>
                                    {
                                        stateFeedback.context.files?.map(item => {
                                                return (<div className={styles.imagePreview} key={item.id}>
                                                    {
                                                        item.file.type.includes("video")
                                                            ? <img src={"/images/video-preview.jpg"} alt=""/>
                                                            : <img src={item.preview} alt=""/>
                                                    }

                                                    <button
                                                        type={"button"}
                                                        className={styles.delete}
                                                        onClick={() => sendFeedback({
                                                            type: "DELETE_FILE",
                                                            data: item.id
                                                        })}
                                                    >
                                                        <svg width="26" height="26" viewBox="0 0 16 16" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 0 1 .708.708L8.707 8l3.647 3.646a.5.5 0 0 1-.708.707L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708Z"
                                                                  fill="currentColor"/>
                                                        </svg>
                                                    </button>
                                                </div>)
                                            }
                                        )
                                    }
                                </div>
                            </div>
                            <div className="formRow">
                                <button type={"submit"} className={"btn btnPrimary"}>
                                    Отправить
                                </button>
                            </div>
                        </form>
                }
            </div>
        </section>
    )
}