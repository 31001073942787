import { assign, createMachine, interpret } from "xstate";
import apiMethods from "../api/apiMethods";

/*
 * Эта машина управляет данными магазина.
 *
 * Здесь делается запрос данных, она же хранит данные в контексте:
 * список товаров, список категорий, количество страниц для данной выдачи, номер текущей страницы.
 *
 *
 * Параметры запроса хранятся и уточняются.
 *
 */

let defaultContext = {
  list: [],
  current_page: 1,
  pages: undefined,
  categories: [],
  errorMsg: [],

  /* Дефолтные значения фильтров */
  category: 0,
  sortDirection: "asc", //Другие значения "asc", "desc"
  sortValue: "points", //Другие значения "asc", "desc"
  // available: false,
  can_buy: false,
  query: "",
};

export const shopMachine = createMachine(
  {
    id: "shop",
    context: { ...defaultContext },
    initial: "init", //получаем GET-параметр из url, чтобы отправить запрос сразу с ним

    states: {
      init: {
        always: {
          actions: "getParameter",
          target: "loading",
        },
      },
      loading: {
        invoke: {
          id: "getData",
          src: "getData",
        },
        on: {
          done: {
            target: "success",
            actions: ["saveData"],
          },
          error: {
            target: "failure",
            actions: ["setError"],
          },
        },
        entry: ["scrollTop"],
      },
      success: {
        on: {
          changeFilters: {
            actions: ["saveData"],
            target: "loading",
          },
          searching: {
            actions: ["setSearchQuery"],
            target: "loading",
          },
          filtering: {
            actions: ["setFilterQuery"],
            target: "loading",
          },
          sorting: {
            actions: ["setSortQuery"],
            target: "loading",
          },
          prevPage: {
            actions: ["changePage"],
            target: "loading",
            cond: "isntFirstPage",
          },
          nextPage: {
            actions: ["changePage"],
            target: "loading",
            cond: "isntLastPage",
          },
        },
      },
      failure: {
        on: {
          retry: {
            target: "loading",
          },
        },
      },
    },
  },
  {
    actions: {
      saveData: assign({
        //в документации message - это event, что кажется нелогичным, потому что event происходит даже если его никто не "слушает", а у message есть цель (получатель), без получатель message не имеет смысла и не происходит.
        list: (ctx, message) => message.data?.gifts?.data ?? ctx.list,

        // "current_page": (ctx, message) => +message.data.current_page ?? ctx.current_page,
        // "pages": (ctx, message) => message.data.pages ?? ctx.pages,
        categories: (ctx, message) => {
          return message.data.categories ?? ctx.categories;
        },
        // "sort": (ctx, message) => message.data.sort ?? ctx.sort,
        sortValue: (ctx, message) => message.data.sortValue ?? ctx.sortValue,
        sortDirection: (ctx, message) =>
          message.data.sortDirection ?? ctx.sortDirection,
        can_buy: (ctx, message) => message.data.can_buy ?? ctx.can_buy,
        category: (ctx, message) => message.data.category ?? ctx.category,
        // "errorMsg": (ctx, message) => message.data.error
      }),

      resetContext: assign({
        list: [],
        current_page: 1,
        categories: [],
        errorMsg: "",
        /* Фильтры */
        category: 0,
        sortValue: "points",
        sortDirection: "asc",
        available: false,
        can_buy: false,
      }),
      setSearchQuery: assign({
        query: (ctx, message) => message.data,
      }),
      setFilterQuery: assign({
        can_buy: (ctx, message) => message.data.can_buy,
      }),
      setSortQuery: assign({
        sortDirection: (ctx, message) => message.data.sortDirection,
        sortValue: (ctx, message) => message.data.sortValue,
      }),
      getParameter: assign({
        query: () => {
          let pageURL = new URL(window.location.href);
          let query = pageURL.searchParams.get("query");

          return query || "";
        },
      }),
      changePage: assign({
        current_page: (ctx, message) => {
          console.log(typeof ctx.current_page, ctx.current_page);
          return message.type === "nextPage"
            ? ctx.current_page + 1
            : ctx.current_page - 1;
        },
      }),

      setError: assign((ctx, message) => message.data),
      scrollTop: () => {
        window.scrollTo(0, 0);
      },
    },
    services: {
      getData: (ctx) => {
        return async function (send) {
          const params = {
            ...(ctx.query ? { search: ctx.query } : {}),
            ...(ctx.category ? { category: ctx.category } : {}),
            ...(ctx.sortValue ? { "orderBy[field]": ctx.sortValue } : {}),
            ...(ctx.sortDirection
              ? { "orderBy[direction]": ctx.sortDirection }
              : {}),
            ...(ctx.can_buy ? { available: ctx.can_buy } : {}),
          };

          const responseCategories = !ctx.categories.length
            ? await apiMethods.bonuses.getCategories()
            : null;

          const response = await apiMethods.bonuses.getGifts(params); //shop.getData(ctx, defaultContext);

          if (response.status === 200) {
            send({
              type: "done",
              data: {
                gifts: response.data,
                ...(responseCategories
                  ? { categories: responseCategories.data.data }
                  : {}),
              },
            });
          } else {
            send({
              type: "error",
              data: response.data.message,
            });
          }
        };
      },
    },
    guards: {
      isntFirstPage: (ctx, message) => ctx.current_page !== 1,
      isntLastPage: (ctx, message) => ctx.current_page !== ctx.pages,
    },
  },
);

/*
export const ShopState = interpret(shopMachine, {devTools: true}).start();*/
