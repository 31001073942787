import { assign, createMachine, interpret } from "xstate";
import apiMethods from "../api/apiMethods";

/*
 * В этой машине хранится состояние авторизации.
 * При первом открытии страницы выполняется проверка:
 * 1. Есть ли в локальном хранилище токен
 * 2. Если токен есть, посылается запрос "/api/init", который проверяет, действителен ли он
 */

const authMachine = createMachine(
  {
    id: "authorization",
    context: {
      type: undefined,
      name: "",
      id: "",
      email: "",
      balance: 0,
      avatar: "",
      can_upload: undefined,
      errorMsg: "",
    },
    initial: "idle",
    states: {
      idle: {
        invoke: {
          src: "check",
          id: "check",
          onDone: {
            target: "auth",
          },
          onError: {
            target: "unAuth",
          },
        },
      },
      unAuth: {
        invoke: {
          id: "clearToken",
          src: "clearToken",
        },
        on: {
          login: {
            target: "loading",
          },
          done: {
            actions: ['reset']
          }
        },
      },
      loading: {
        invoke: {
          id: "authentication",
          src: "authentication",
        },
        on: {
          success: {
            target: "auth",
          },
          error: {
            target: "unAuth",
            actions: ["saveError"],
          },
        },
      },
      auth: {
        initial: "noData",
        states: {
          noData: {
            invoke: {
              src: "getUserData",
              id: "getUserData",
            },
            on: {
              done: {
                target: "getDataUser",
                actions: ["saveUserData", "clearError"],
              },
              error: {
                target: "error",
                actions: ["saveError"],
              },
            },
          },
          error: {
            target: "unAuth",
            actions: ["saveError"],
          },
          getDataUser: {
            target: "auth"
          },
        },
        on: {
          logout: {
            target: "unAuth",
          },
        },
      },
    },
  },
  {
    actions: {
      saveUserData: assign({
        type: (ctx, message) => message.data.type,
        name: (ctx, message) => message.data.name,
        email: (ctx, message) => message.data.email,
        id: (ctx, message) => message.data.id,
        balance: (ctx, message) => message.data.balance,
        avatar: (ctx, message) => message.data.avatar,
        can_upload: (ctx, message) => message.data.can_upload,
      }),
      saveError: assign({
        errorMsg: (ctx, message) => message.data,
      }),
      reset: assign({
        type: undefined,
        name: "",
        id: "",
        email: "",
        balance: 0,
        avatar: "",
        can_upload: undefined,
        errorMsg: "",
      }),
      clearError: assign({
        errorMsg: "",
      }),
    },
    services: {
      check: () => {
        const token = localStorage.getItem("sessionID");
        if (token) {
          return (async () => {
            const response = await apiMethods.authentication.user();
            response.status === 401
              ? Promise.reject("Unauthorized")
              : Promise.resolve();
          })();
        }
        return Promise.reject();
      },
      authentication: (ctx, message) =>
        async function (send) {
          await apiMethods.authentication
            .auth(message.data)
            .then((response) => {
            
              if (response.status === 200) {
                localStorage.setItem("sessionID", response.data.access.token);
                
                send({
                  type: "success",
                });
              }
            })
            .catch((response) => {
              console.log("error => ", response.response.data.message);

              send({
                type: "error",
                data: response.response.data.message,
              });
            });
        },
      clearToken: async (send) => {
        const token = localStorage.getItem("sessionID");

        try {
          await apiMethods.authentication.logout();
          send({
            type: 'done'
          })
        } catch (e) {
          console.log("err => ", e);
        }

        if (token) {
          localStorage.removeItem("sessionID");
        }
      },
      getUserData: () => {
        const token = localStorage.getItem("sessionID");
        if (token) {
          return async function (send) {
            try {
              const response = await apiMethods.authentication.user();

              if (response.status === 200) {
                send({
                  type: "done",
                  data: response.data.data,
                });
              }
            } catch (error) {
              send({
                type: "error",
                data: response.data.data,
              });
            }
          };
        }
      },
    },
  },
);

export const AuthState = interpret(authMachine, { devTools: true }).start();
