import {Header} from "../modules/Header/Header";
import {Footer} from "../modules/Footer/Footer";
import {useEffect} from "react";
import { AuthState } from "../xstate/auth.machine";
import { ResetPassForm } from './../modules/ResetPassForm/ResetPassForm';

export function RestorePassword() {
    // let [stateAuth, _] = useActor(AuthState);

    useEffect(() => {
        document.title = "Восстановление пароля | Программа лояльности SK ZIC";
    }, []);

    return (<>
        {/* {
            (stateAuth.matches("idle") || stateAuth.matches("loading")) &&
            <div className={"fullPageLoader"}><Loader/></div>
        } */}
        <Header />
        <main id={"main"} role="main">
            <ResetPassForm/>
        </main>
        <Footer/>
    </>)
}