import {Outlet} from "react-router-dom";
import styles from "./AccountLayout.module.scss";
import {AccountMenu} from "../AccountMenu/AccountMenu";
import {Logo} from "../Header/Header";

export function AccountLayout() {
    return (
        <main id="main" className={styles.flexContainer} role="main">
            <aside className={styles.sidebar}>
                <div className={styles.sidebarWrapper}>
                    <Logo dark/>
                    <AccountMenu/>
                </div>
            </aside>
            <section className={styles.workarea}>
                <Outlet/>
            </section>
        </main>
    )
}