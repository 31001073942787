import {Header} from "../modules/Header/Header";
import {Footer} from "../modules/Footer/Footer";
import {useEffect} from "react";
import {Outlet} from "react-router-dom";

export function Shop() {

    useEffect(() => {
        document.title = "Витрина подарков | Программа лояльности SK ZIC";
    }, []);

    return (<>
        <style>
            {`body {
                background-color: #fff;
            }`}
        </style>
        <Header/>
        <main id="main" role="main">
            <div className="wrapper">
                <h1 className={"line"}>Витрина подарков</h1>
            </div>
            <Outlet/>
        </main>
        <Footer/>
    </>)
}