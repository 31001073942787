import apiUrls from "../urls";

export default function (api) {
  return {
    getGifts(params = {}) {
      // const { search, category, available } = params;
      // const url = search
      //   ? `${apiUrls.MORE_BONUSES}?search=${search}`
      //   : category
      //   ? `${apiUrls.MORE_BONUSES}?category=${category}`
      //   : search && category
      //   ? `${apiUrls.MORE_BONUSES}?category=${category}&search=${search}`
      //   : apiUrls.MORE_BONUSES;

      return api.get(apiUrls.MORE_BONUSES, { params });
    },
    getOrders(params) {
      const { perPage, currentPage } = params;

      return api.get(
        `${apiUrls.SELLER_ORDERS}?perPage=${perPage}&currentPage=${currentPage}`,
      );
    },
    setOrder(id) {
      return api.post(`${apiUrls.SELLER_ORDERS}`, { prize: id });
    },
    getCategories() {
      return api.get(`${apiUrls.GIFTS_CATEGORIES}`);
    },
  };
}
