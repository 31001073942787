import apiUrls from "../urls";

export default function (api) {
  return {
    getGoods() {
      return api.get(apiUrls.GOODS);
    },
    getData(type, params) {
      const keys = Object.keys(params).filter(key => params[key]?.length > 0)
      const paramsData = {}

      keys.forEach(key => {
        if (Array.isArray(params[key])) {
          paramsData[`${key}[]`] = params[key]
        } else {
          paramsData[`${key}`] = params[key]
        }
      })


      return api.get(`${type}/statistics`, { params: paramsData });
    },
  };
}
