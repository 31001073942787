import { assign, createMachine } from "xstate";
import apiMethods from "../api/apiMethods";

export const cardMachine = createMachine(
  {
    id: "card",
    initial: "init",
    context: {
      errorMessage: [],
      promocode: "",
    },
    states: {
      init: {
        on: {
          purchase: {
            target: "loading",
          },
          resetting: {
            actions: ["reset"],
          },
        },
      },
      loading: {
        invoke: {
          id: "request",
          src: "request",
        },
        on: {
          done: {
            target: "show_promocode",
            actions: ["saveData"],
          },
          error: {
            target: "init",
            actions: ["saveError"],
          },
        },
      },
      show_promocode: {
        on: {
          resetting: {
            actions: ["reset"],
            target: "init",
          },
        },
      },
    },
  },
  {
    actions: {
      saveData: assign({
        // promocode: (ctx, message) => message.data ?? ctx.promocode,
      }),
      reset: assign({
        errorMessage: [],
        promocode: "",
      }),
      saveError: assign({
        errorMessage: (ctx, message) => message.data ?? ctx.errorMessage,
      }),
    },
    services: {
      request: (ctx, message) => {
        return async function (send) {
          try {
            const response = await apiMethods.bonuses.setOrder(message.data);
            if (response.status === 200) {
              send({ type: "done", data: response.data });
            }
          } catch (err) {
            send({ type: "error", data: err.response.data.message });
          }
        };
      },
    },
  },
);
