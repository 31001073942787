import styles from "./Filter.module.scss";
import { Dialog, DialogDisclosure, useDialogState } from "reakit";
import { Icon } from "../SVGsprite/svgsprite";
import clsx from "clsx";
import { useActor } from "@xstate/react";
import { useEffect, useLayoutEffect, useState } from "react";
import { Logo } from "../Header/Header";
import { useNavigate } from "react-router-dom";

export function Filter(props) {
  let [state, send] = useActor(props.ShopState);
  let [isDesktop, setIsDesktop] = useState(false);
  let [searchQ, setSearchQ] = useState(state.context.query);
  let navigate = useNavigate();

  useLayoutEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 1152px)");
    setIsDesktop(mediaQuery.matches);

    mediaQuery.addEventListener("change", () =>
      setIsDesktop(mediaQuery.matches),
    );
  }, []);

  // useEffect(() => {
  //   window.addEventListener('keydown', handleKeyDown)

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown)
  //   }
  // }, [])

  const searchSubmit = function (event) {
    event.preventDefault();
    navigate(`?query=${event.target?.search?.value ?? event.target.value}`);
    send({ type: "searching", data: event.target?.search?.value ?? event.target.value});
  };

  const handleKeyDown = function(event) {
    if(event.key === 'Enter') {
      event.preventDefault()
      searchSubmit(event)
    }
  }

  return (
    <section className={styles.filter}>
      <div className={clsx("wrapper", styles.wrapper)}>
        {isDesktop && <Options send={send} state={state} />}
        <div className={styles.filterSettings}>
          {isDesktop && <Sorting send={send} state={state} />}
        </div>

        <form
          className={clsx("search", styles.search)}
          onSubmit={(event) => searchSubmit(event)}
        >
          <label htmlFor="searchField" className={"visuallyHidden"}>
            Поиск по призам
          </label>
          <input
            onChange={(event) => setSearchQ(event.target.value)}
            type="text"
            id="searchField"
            name="search"
            value={searchQ.toString()}
            placeholder={"Поиск"}
            inputMode={"search"}
            onKeyDown={handleKeyDown}
          />
          <button
            type={"submit"}
            className="clear"
            onClick={() => setSearchQ("")}
          >
            <span className="visuallyHidden">Очистить поле</span>
            <Icon name={"icon-close"} sizeW="24" sizeH="24" />
          </button>

          <button className={clsx("btn btnPrimary")} type={"submit"}>
            <Icon name={"icon-search"} sizeH="24" sizeW="24" />
            <span className={"visuallyHidden"}>Отправить запрос</span>
          </button>
        </form>

        {isDesktop && <CategoryFilter send={send} state={state} />}

        {!isDesktop && <MobileFilter send={send} state={state} />}
      </div>
    </section>
  );
}

function Sorting(props) {
  let changeSorting = function (event) {
    props.send({
      type: "sorting",
      data: {
        sortDirection: event.target.value,
        sortValue: event.target.name,
      },
    });
  };

  return (
    <div className={styles.sorting}>
      <div className="selectContainer">
        <label htmlFor={"sort"} className="visuallyHidden">
          Сортировка товаров
        </label>
        <select
          name="points"
          id="points"
          onChange={(event) => changeSorting(event)}
          defaultValue={props.state.context.sortValue}
        >
          <option value="asc">Сначала дешевле</option>
          <option value="desc">Сначала дороже</option>
          {/*<option value="new_first">Сначала новинки</option>*/}
        </select>
      </div>
    </div>
  );
}

function Options(props) {
  let changeOption = function (event) {
    props.send({
      type: "filtering",
      data: {
        [event.target.name]: event.target.checked,
      },
    });
  };

  return (
    <div className={styles.options}>
      <div className={clsx(styles.optionItem, "customCheckbox")}>
        <input
          id={"can_buy"}
          name={"can_buy"}
          type="checkbox"
          onChange={(event) => changeOption(event)}
        />
        <label htmlFor={"can_buy"}>На что хватает моих баллов</label>
      </div>
      {/*<div className={clsx(styles.optionItem, "customCheckbox")}>*/}
      {/*  <input*/}
      {/*    id={"available"}*/}
      {/*    name={"available"}*/}
      {/*    type="checkbox"*/}
      {/*    onChange={(event) => changeOption(event)}*/}
      {/*  />*/}
      {/*  <label htmlFor={"available"}>Только в наличии</label>*/}
      {/*</div>*/}
    </div>
  );
}

function CategoryFilter(props) {
  let changeCategory = function (cat) {
    props.send({
      type: "changeFilters",
      data: {
        category: cat,
      },
    });
  };

  return (
    <section aria-label={"Фильтр по категориям"} className={styles.categories}>
      <div className={styles.categoryItem}>
        <input
          type="radio"
          name="category"
          id="allItems"
          value={0}
          checked={props.state.context.category === 0}
          onChange={(event) => changeCategory(0)}
        />
        <label htmlFor="allItems">Все подарки</label>
      </div>
      {props.state.context.categories?.map((item) => {
        return (
          <div className={styles.categoryItem} key={item.id}>
            <input
              type="radio"
              name="category"
              id={`cat${item.id}`}
              value={item.id}
              checked={props.state.context.category === item.id}
              onChange={() => changeCategory(item.id)}
            />
            <label htmlFor={`cat${item.id}`}>{item.name}</label>
          </div>
        );
      })}
    </section>
  );
}

function MobileFilter(props) {
  const dialog = useDialogState({
    animated: true,
    hideOnEsc: true,
    hideOnClickOutside: true,
  });

  return (
    <>
      <DialogDisclosure className={styles.burger} {...dialog}>
        <Icon name={"icon-filter"} sizeW="32" sizeH="32" />

        <span className={"visuallyHidden"}>Показать фильтр</span>
      </DialogDisclosure>

      <Dialog
        {...dialog}
        className={clsx("modal", styles.modalFilter)}
        aria-label="Фильтр подарков"
      >
        <div className="modalHead">
          <Logo />

          <button className={"modalClose"} onClick={dialog.hide}>
            <Icon name={"icon-close"} sizeW="32" sizeH="32" />
            <span className="visuallyHidden">Закрыть меню фильтров</span>
          </button>
        </div>
        <div className="modalBody">
          <div className={styles.filterMobile}>
            <Sorting send={props.send} state={props.state} />
            <Options send={props.send} state={props.state} />
            <CategoryFilter send={props.send} state={props.state} />
          </div>
        </div>
      </Dialog>
    </>
  );
}
