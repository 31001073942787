import apiUrls from '../urls';

export default function (api) {
    return {
        getData(ctx) {
            const query = {
                perPage: ctx.perPage,
                currentPage: ctx.currentPage,
                ...(ctx?.orderBy?.field || ctx?.orderBy?.direction ? {
                    orderBy: {
                        ...(ctx.orderBy.field ? {field: ctx.orderBy.field } : {}),
                        ...(ctx.orderBy.field ? {field: ctx.orderBy.field } : {}),
                    }
                } : {})
            }
            return api.post(`${apiUrls.SELLER_RECEIPTS}`, query)
        },
    }
};