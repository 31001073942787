const PERSONAL_URL = "/user";
const AUTH_URL = "/auth";
const SHOP_URL = "/shop";
const USER_URL = "/user";
const OWNER = "/owner";
const SELLER = "/seller";
const REFERENCES = "/references";

export default {
  AUTH: AUTH_URL,
  USER: USER_URL,
  LOGIN: `${AUTH_URL}/login`,
  LOGOUT: `${AUTH_URL}/logout`,
  FORGOT_PASSWORD: `${AUTH_URL}/forgot`,
  RESTORE_PASSWORD: `${USER_URL}/password`,
  PERSONAL_FULL: `${PERSONAL_URL}/account`,
  PERSONAL_UPD: `${PERSONAL_URL}/password`,
  PERSONAL_DELETE_AVATAR: `${PERSONAL_URL}/delete-avatar`,
  PERSONAL_RECEIPTS: `${PERSONAL_URL}/receipts`,
  INIT: "/init",
  GIFTS_CATEGORIES: `${SELLER}/gifts/categories`,
  SELLER_ORDERS: `${SELLER}/orders`,
  SELLER_RECEIPTS: `${SELLER}/receipts`,
  SELLER_REPORT: `${SELLER}/export/sales`,
  UPLOAD_VOUCHER: `${SELLER}/receipts/store`,
  REGIONS: `${REFERENCES}/regions`,
  BANNERS: `${REFERENCES}/banners`,
  GOODS: `${REFERENCES}/goods`,
  MORE_BONUSES: `${SELLER}/gifts`,
  GIFTS: `${PERSONAL_URL}/gifts`,
  SHOP: SHOP_URL,
  SHOP_MAIN: `${SHOP_URL}/main`,
  OWNERS: `${REFERENCES}/owners`,
  DOCUMENTS: `${REFERENCES}/documents`,
  FAQ: `${REFERENCES}/faq`,
  OWNER_SALES: `${OWNER}/sales`,
  RULES: "/rules",
  FEEDBACK: "/feedback/send",
  DIST_SALES: "/distributor/sales",
  SALE_POINT_DISTRIBUTOR: "/distributor/sale_points",
  DISTRIBUTOR_OWNERS: "/distributor/owners",
  SALE_POINT_OWNER: "/owner/sale_points",
  SALE_POINTS: `${OWNER}/sale_points`,
  SALE_POINTS_ALL: `${REFERENCES}/sale_points`,
  SELLER: `${OWNER}/sellers`,
  SELLER_SALES: `${SELLER}/sales`,
  SELLER_TRANSACTIONS: `${SELLER}/transactions`,
};
