import apiUrls from "../urls";

export default function (api) {
  return {
    getData() {
      return api.get(apiUrls.PERSONAL_FULL);
    },
    updateUserData(data) {
      return api.post(apiUrls.USER, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    updatePassword(data) {
      return api.post(apiUrls.PERSONAL_UPD, data);
    },
    user() {
      return api.get(apiUrls.PERSONAL_DELETE_AVATAR);
    },
  };
}
