import apiUrls from "../urls";

export default function (api) {
  return {
    getSellerSales(perPage, currentPage) {
      return api.get(
        `${apiUrls.SELLER_SALES}?perPage=${perPage}&currentPage=${currentPage}`,
      );
    },
    getOwnerSales(perPage, currentPage) {
      return api.get(
        `${apiUrls.OWNER_SALES}?perPage=${perPage}&currentPage=${currentPage}`,
      );
    },
    getDistSales(perPage, currentPage) {
      return api.get(
        `${apiUrls.DIST_SALES}?perPage=${perPage}&currentPage=${currentPage}`,
      );
    },
    getReportSales() {
      return api.get(`${apiUrls.SELLER_REPORT}`);
    },
  };
}
