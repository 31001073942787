import {Dialog, DialogBackdrop} from "reakit";
import clsx from "clsx";
import styles from "./SuccessPopup.module.scss";
import { Icon } from "../SVGsprite/svgsprite";
import { useEffect } from "react";
import { CustomModal } from "../CustomModal/CustomModal";

export function SuccessPopup(props) {
    const {
        isOpen,
        title,
        text,
        onClose,
    } = props

    return (    
        <CustomModal isOpen={isOpen} onClose={onClose}>
            <>
                <div className="modalHead modalCardOneChild">
                    <button className={"modalClose"} style={{marginLeft: 'auto'}} onClick={() => {
                        onClose();
                    }}>
                        <Icon name={"icon-close"} sizeW="32" sizeH="32"/>
                        <span className="visuallyHidden">
                            Закрыть
                        </span>
                    </button>
                </div>

                <div className={clsx("modalBody", styles.info)}>
                    <h2 className={"textCenter"}>{title}</h2>
                    {Boolean(text) &&<p className={"textCenter"}>{text}</p>}
                    <Icon  name={"icon-success"} sizeH="125" sizeW="125" />
                </div>
            </>
        </CustomModal>


    )
}