import {assign, createMachine} from "xstate";
import apiMethods from "../api/apiMethods";

export const myGiftsMachine = createMachine({
    id: "myGifts",
    context: {
        list: [],
        current_page: 1,
        perPage: 10,
        meta: {},
        pages: undefined,
        errorMsg: [],
    },
    initial: "loading",
    states: {
        "loading": {
            invoke: {
                id: "getData",
                src: "getData",
            },
            on: {
                "done": {
                    target: "success",
                    actions: ["saveData"]
                },
                "error": {
                    target: "failure",
                    actions: ["setError"]
                }
            },
            entry: "scrollTop",
        },
        "success": {
            on: {
                "prevPage": {
                    actions: ["changePage"],
                    target: "loading",
                    cond: "isntFirstPage"
                },
                "nextPage": {
                    actions: ["changePage"],
                    target: "loading",
                    cond: "isntLastPage"
                },
            }
        },
        "failure": {
            on: {
                "retry": {
                    target: "loading"
                }
            }
        }
    }
}, {
    actions: {
        "saveData": assign({
            "list": (ctx, message) => message.data.data,
            "current_page": (ctx, message) => +message.data.meta.currentPage,
            "pages": (ctx, message) => message.data.meta.lastPage,
            "meta": (ctx, message) => message.data.meta
        }),
        "changePage": assign({
            "current_page": (ctx, message) => message.type === "nextPage" ? ctx.current_page + 1 : ctx.current_page - 1
        }),
        "setError": assign((ctx, message) => message.data),
        "scrollTop": () => {
            /* document.getElementById("receiptsTable")?.scrollTo(0, 0); */
            window.scrollTo(0, 0);
        }
    },
    services: {
        "getData": (ctx) => async function (send) {
                const response = await apiMethods.bonuses.getOrders({
                    perPage: ctx.perPage,
                    currentPage: ctx.current_page
                });
                
                if (response.status === 200) {
                    send({
                        type: "done",
                        data: response.data,
                    })
                } else {
                    send({
                        type: "error",
                        data: response.data.message
                    })
                }
            }
    },
    guards: {
        "isntFirstPage": (ctx, message) => ctx.current_page !== 1,
        "isntLastPage": (ctx, message) => ctx.current_page !== ctx.pages,
    }
});