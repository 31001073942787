import { Link } from "react-router-dom";
import { useMachine } from "@xstate/react";
import styles from "./SalePointApplication.module.scss";
import { salePointsMachine } from "../../../xstate/salePoints.machine";
import { Pagination } from "@/js/modules/Pagination/Pagination";
import { Table } from "@/js/modules/Table/Table";
import { ownerSalePointsColumns } from "@/js/constants";

/* Список торговых точек */

const i18n = {
  active: {
    color: "#1AA00E",
  },
  in_process: {
    color: "#000",
  },
  inactive: {
    color: "#D02239",
  },
  reject: {
    color: "#626262",
  },
};

export function SalePointApplication() {
  const [stateMySalePoints, sendMySalePoints] = useMachine(salePointsMachine);

  const prepareBodyData = (data) =>
    data.reduce((acc, cur) => {
      acc.push({
        name: cur.name,
        address: cur.address,
        status: (
          <span style={{ color: i18n[cur.status.slug]?.color || null }}>
            {cur.status.i18n}
          </span>
        ),
      });
      return acc;
    }, []);

  const { meta } = stateMySalePoints.context;

  return (
    <section className={styles.container}>
      <div className="wrapper">
        <div className={styles.header}>
          <h2>Торговые точки</h2>
          <Link
            to="/account/sale-point-register"
            className="btn btnPrimary btnIcon"
          >
            Заявка на регистрацию торговой точки
          </Link>
        </div>
        <Table
          headers={ownerSalePointsColumns}
          tableData={prepareBodyData(stateMySalePoints.context.list)}
          isLoading={stateMySalePoints.matches("loading")}
        />
        {stateMySalePoints.context.list.length &&
        meta.lastPage > 1 &&
        !stateMySalePoints.matches("loading") ? (
          <Pagination
            pages={stateMySalePoints.context.pages}
            current_page={stateMySalePoints.context.current_page}
            send={sendMySalePoints}
          />
        ) : (
          <></>
        )}
      </div>
    </section>
  );
}
