import apiUrls from "../urls";

export default function (api) {
  return {
    getSalePoints(perPage, currentPage) {
      const url = perPage && currentPage ? 
      `${apiUrls.SALE_POINTS}?perPage=${perPage}&currentPage=${currentPage}` :
      apiUrls.SALE_POINTS

      return api.get(url);
    },
    postSalePoints(body) {
      return api.post(apiUrls.SALE_POINTS, body);
    },
    getSellers(perPage, currentPage) {
      const url = perPage && currentPage ? 
      `${apiUrls.SELLER}?perPage=${perPage}&currentPage=${
          currentPage
        }` : apiUrls.SELLER

      return api.get(url);
    },
    postSeller(body) {
      return api.post(apiUrls.SELLER, body);
    },
    editSeller(id, body) {
      return api.post(`${apiUrls.SELLER}/${id}`, body)
    },
    getSeller(id) {
      return api.get(`${apiUrls.SELLER}/${id}`)
    },
    getStatuses() {
      return api.get(`/references/users/statuses`)
    }
  };
}
