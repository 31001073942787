import {Dialog, DialogBackdrop} from "reakit";
import clsx from "clsx";
import styles from "./RegisterInfoModal.module.scss";
import { Link } from 'react-router-dom';
import { Icon } from "../SVGsprite/svgsprite";

export function RegisterInfoModal(props) {

    return (
        <DialogBackdrop {...props.dialog} className={"backdrop"}>
            <Dialog
                {...props.dialog}
                aria-label="Как зарегистрироваться?"
                className={clsx("modal", styles.RegisterInfo)}
            >
                <div className="modalHead modalCardOneChild">
                    <button className={"modalClose"} style={{marginLeft: 'auto'}} onClick={() => {
                        props.dialog.hide();
                    }}>
                        <Icon name={"icon-close"} sizeW="32" sizeH="32"/>
                        <span className="visuallyHidden">
                            Закрыть
                        </span>
                    </button>
                </div>

                <div className="modalBody">
                    <h2 className={"textCenter"}>Как зарегистрироваться?</h2>
                    <p>Для получения логина и пароля к системе обратитесь к вашему дистрибьютору</p>
                    <p>Если вы испытываете с этим сложности - свяжитесь с нами</p>
                    <Link
                        to={"/feedback"}
                        className={clsx("btn", "btnPrimary", styles.feedbackLink)}
                    >
                        Связаться с нами
                    </Link>
                </div>
            </Dialog>
        </DialogBackdrop>
    )
}