import {RouterProvider } from "react-router-dom";
import {ScrollToTop} from "./js/modules/Util/ScrollToTop";
import QrScanner from "qr-scanner";
import router from './js/router'

function App() {
  return (
    <div className="container">
      <RouterProvider router={router()}>
        <ScrollToTop />
      </RouterProvider>
    </div>
  )
}

export default App;
