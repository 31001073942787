// import {BrowserRouter, Route, Routes} from "react-router-dom";

import {
  Route,
  useNavigate,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { useActor } from "@xstate/react";
import { Home } from "@/js/Pages/Home";
import { Page404 } from "@/js/Pages/Page404";
import { Shop } from "@/js/Pages/Shop";
import { Account } from "@/js/Pages/Account";
import { MyInfo } from "@/js/modules/MyInfo/MyInfo";
import { MyReceipts } from "../modules/MyReceipts/MyReceipts";
import { MyTransactions } from "../modules/MyTransactions/MyTransactions";
import { MySales } from "@/js/modules/MySales/MySales";
import { MyGifts } from "@/js/modules/MyGifts/MyGifts";
import { Feedback } from "@/js/Pages/Feedback";
import { ShopPage } from "@/js/modules/ShopPage/ShopPage";
import { AuthState } from "@/js/xstate/auth.machine";
import { Auth } from "@/js/Pages/Auth";
import { AccountLayout } from "@/js/modules/AccountLayout/AccountLayout";
import { UploadReceipt } from "@/js/modules/UploadReceipt/UploadReceipt";
import { SellerStat } from "@/js/modules/SellerStat/SellerStat";
import { ShopStat } from "@/js/modules/ShopStat/ShopStat";
import { DistributorStat } from "@/js/modules/DistributorStat/DistributorStat";
import { Statistic } from "@/js/modules/Statistic/Statistic";
import { ProducerStat } from "@/js/modules/ProducerStat/ProducerStat";
import { RestorePassword } from "@/js/Pages/RestorePassword";
import { SalePointApplication } from "@/js/modules/CreationPages/SalePointApplication/SalePointApplication";
import { SellerRegister } from "@/js/modules/CreationPages/SellerRegister/SellerRegister";
import { OwnerRegister } from "@/js/modules/CreationPages/OwnerRegister/OwnerRegister";
import { SalePointApplicationForm } from "@/js/modules/CreationPages/SalePointApplication/SalePointApplicationForm/SalePointApplicationForm";
import { EditSellerForm } from "@/js/modules/CreationPages/SellerRegister/EditSellerForm/EditSellerForm";
import { OwnerRegisterForm } from "@/js/modules/CreationPages/OwnerRegister/OwnerRegisterForm/OwnerRegisterForm";
import { SalePointRegister } from "@/js/modules/CreationPages/SalePointRegister/SalePointRegister";
import { SalePointRegisterForm } from "@/js/modules/CreationPages/SalePointRegister/SalePointRegisterForm/SalePointRegisterForm";
import { SalePointEditForm } from "@/js/modules/CreationPages/SalePointRegister/SalePointEditForm/SalePointEditForm";
import { AddSellerForm } from "@/js/modules/CreationPages/SellerRegister/AddSellerForm/AddSellerForm";

const router = () => {
  const [authState] = useActor(AuthState);

  return createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          element={
            !["distributor", "owner"].includes(authState.context.type) ? (
              <Home />
            ) : (
              <Page404 />
            )
          }
        />
        <Route path="/shop" element={<Shop />}>
          {/* <Route element={<Showcase/>}/> */}
          <Route index element={<ShopPage />} />
        </Route>
        <Route path="/feedback" element={<Feedback />} />

        <Route path="/account" element={<Account />}>
          <Route element={<AccountLayout />}>
            <Route index element={<MyInfo />} />

            {/* Страница регистрации владельца торговой точки доступна только дистрибьютору */}
            <Route
              path="owners"
              element={
                authState.context.type === "distributor" ? (
                  <OwnerRegister />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница формы регистрации владельца торговой точки доступна только дистрибьютору */}
            <Route
              path="owner-add"
              element={
                authState.context.type === "distributor" ? (
                  <OwnerRegisterForm />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница формы регистрации торговой точки доступна только дистрибьютору  */}
            <Route
              path="sale-point-add"
              element={
                authState.context.type === "distributor" ? (
                  <SalePointRegisterForm />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница формы редактирования торговой точки доступна только дистрибьютору  */}
            <Route
              path="sale-point-edit/:id"
              element={
                authState.context.type === "distributor" ? (
                  <SalePointEditForm />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница регистрации торговой точки доступна только дистрибьютору */}
            <Route
              path="sale-points"
              element={
                authState.context.type === "distributor" ? (
                  <SalePointRegister />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница регистрации торговой точки доступна только владельцу точки */}
            <Route
              path="sale-point"
              element={
                authState.context.type === "owner" ? (
                  <SalePointApplication />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница формы регистрации торговой точки доступна только владельцу точки */}
            <Route
              path="sale-point-register"
              element={
                authState.context.type === "owner" ||
                authState.context.type === "distributor" ? (
                  <SalePointApplicationForm />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница регистрации продавца доступна только владельцу точки */}
            <Route
              path="sellers"
              element={
                authState.context.type === "owner" ? (
                  <SellerRegister />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница формы регистрации продавца доступна только владельцу точки */}
            <Route
              path="seller-add"
              element={
                authState.context.type === "owner" ? (
                  <AddSellerForm />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница формы редактирования продавца доступна только владельцу точки  */}
            <Route
              path="seller-edit/:id"
              element={
                authState.context.type === "owner" ? (
                  <EditSellerForm />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница загрузки чека доступна только продавцу и владельцу точки */}
            <Route
              path="upload"
              element={
                authState.context.type === "seller" ||
                authState.context.type === "owner" ? (
                  <UploadReceipt />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница со списком загруженных чеков доступна только продавцу и владельцу */}
            <Route
              path="receipts"
              element={
                authState.context.type === "seller" ||
                authState.context.type === "owner" ? (
                  <MyReceipts />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница со списком транзакций баллов доступна только продавцу */}
            <Route
              path="transactions"
              element={
                authState.context.type === "seller" ? (
                  <MyTransactions />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница Мои продажи доступна только продавцу */}
            <Route
              path="sales"
              element={
                authState.context.type === "seller" ? <MySales /> : <Page404 />
              }
            />

            {/* Страница приобретенных призов доступна только продавцу и владельцу точки */}
            <Route
              path="gifts"
              element={
                authState.context.type === "seller" ||
                authState.context.type === "owner" ? (
                  <MyGifts />
                ) : (
                  <Page404 />
                )
              }
            />

            {/* Страница статистики недоступна для продавца */}
            <Route
              path="statistics"
              element={
                <>
                  {authState.context.type === "seller" ? (
                    <Page404 />
                  ) : (
                    <Statistic />
                  )}
                </>
              }
            >
              {authState.context.type === "owner" && (
                <>
                  <Route index element={<ShopStat />} />
                  <Route path=":sellerID" element={<SellerStat />} />
                </>
              )}

              {authState.context.type === "distributor" && (
                <>
                  <Route index element={<DistributorStat />} />
                  <Route path=":shopID" element={<ShopStat />} />
                  <Route path=":shopID/:sellerID" element={<SellerStat />} />
                </>
              )}
              {authState.context.type === "producer" && (
                <>
                  <Route index element={<ProducerStat />} />
                  <Route path=":distributorID" element={<DistributorStat />} />
                  <Route path=":distributorID/:shopID" element={<ShopStat />} />
                  <Route
                    path=":distributorID/:shopID/:sellerID"
                    element={<SellerStat />}
                  />
                </>
              )}
            </Route>
          </Route>
        </Route>

        <Route path="/auth">
          <Route index element={<Auth />} />
          <Route path="forgot" element={<RestorePassword />} />
        </Route>
        <Route path="*" element={<Page404 />} />
        {/* question/open question/close, question/speaker  */}
        {/* <Route path="*" element={<NotFound />} />  */}
      </>,
    ),
  );
};

export default router;

// function Router() {

//   const [authState] = useActor(AuthState);

//   const router =

//   return router

//     // <BrowserRouter>
//     //   <ScrollToTop />
//     //   <Routes>
//     //     <Route path="/" element={<Home/>}/>

//         // <Route path="/auth">
//         //   <Route index element={<Auth />} />
//         //   <Route path="forgot" element={<RestorePassword/>}/>
//         // </Route>
//     //     <Route path="*" element={<Page404/>}/>
//     //   </Routes>
//     // </BrowserRouter>
// }

// export default Router
