import apiUrls from "../urls";

export default function (api) {
  return {
    getSellerTransactions(perPage, currentPage) {
      return api.get(
        `${apiUrls.SELLER_TRANSACTIONS}?perPage=${perPage}&currentPage=${currentPage}`,
      );
    },
  };
}
