const PASSWORD_MIN_LENGTH = 7;

const emailErrrorMessages = {
    empty: "Поле не может быть пустым",
    invalidInput: "Поле заполнено некорректно",
};

const passwordErrorMessages = {
    empty: "Поле не может быть пустым",
    short: `Минимально допустимое количество символов: ${PASSWORD_MIN_LENGTH}`
};

export const validateEmail = (value) => {
    const reg =  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    if (value?.length === 0) {
        return emailErrrorMessages.empty;
    } else if (!value.match(reg)) {
        return emailErrrorMessages.invalidInput;
    }
    return "";
};

export const validatePassword = (value) => {
    if (value?.length === 0) {
        return passwordErrorMessages.empty;
    } else if (value?.length < PASSWORD_MIN_LENGTH) {
        return passwordErrorMessages.short;
    }
    return "";
};