import styles from "./Table.module.scss";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Loader } from "./../../Loader/Loader";

/* Список призов на главной */

export const DefaultTable = (props) => {
  const {
    headerData,
    bodyData,
    isTableLoading,
    className = "",
    withActions = false,
    onCellClick
  } = props;

  return (
    <>
      {isTableLoading ? (
        <Loader />
      ) : bodyData?.length ? (
        <div
          style={{ overflowX: "auto", marginBottom: "30px" }}
          className={!!className ? className : ""}
        >
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr className={styles.tableHead}>
                {Object.values(headerData).map((header, index) => (
                  <th
                    key={index + header}
                    className={`${styles.headCell} ${styles.cell}`}
                  >
                    {header}
                  </th>
                ))}
                ,
              </tr>
            </thead>
            <tbody>
              {bodyData.map((rowItem, itemIndex) => (
                <tr key={itemIndex} className={styles.row}>
                  {Object.values(rowItem).map((cellItem, index) => (
                    <td onClick={() => index === 8 ? props.onCellClick(rowItem) : () => ({})} key={itemIndex + index} className={styles.cell}>
                      {cellItem}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
