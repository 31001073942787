import {useEffect} from "react";
import {Header} from "../modules/Header/Header";
import {Footer} from "../modules/Footer/Footer";
import {FeedbackForm} from "../modules/FeedbackForm/FeedbackForm";

export function Feedback(){

    useEffect(() => {
        document.title = "Обратная связь | Программа лояльности SK ZIC";
    }, []);

    return(<>
        <Header/>
        <main id="main" role="main">
            <FeedbackForm/>
        </main>
        <Footer/>
    </>)
}
