import {useCallback, useEffect, useState} from "react";
import styles from "./FAQ.module.scss";
import {AccordionItem} from "../Accordion/AccordionItem";
import apiMethods from "../../api/apiMethods";

export function FAQ() {
    const [list, setList] = useState([]);
    const [rules, setRules] = useState();

    const fetchFaq = useCallback(async () => {
        const {data} = await apiMethods.faq.getFaq();
        setList(data.data)
    }, []);

    const fetchRules = useCallback(async () => {
      try {
        const {data} = await apiMethods.docs.getDocs();
        const rules = data.data.find(item => item.type === 'rules')
        setRules(rules || null)
      } catch (error) {
        console.log(error);
      }
    }, []);

    useEffect(() => {
        fetchFaq();
    }, [fetchFaq]);

    useEffect(() => {
        fetchRules();
    },[fetchRules]);

    return (
        <section className={styles.faq}>
            <div className="wrapper">
                <h2 className="textCenter">
                    Часто задаваемые вопросы
                </h2>
                
                {
                    (list.length > 0) &&
                    <div className={styles.faqContainer}>
                        {
                            list.map(
                              (item, idx) => (
                                <AccordionItem
                                  key={`item${idx}`}
                                  title={item.question}
                                  content={item.answer}
                                />
                            ))
                        }
                    </div>
                }

                <div className={styles.rulesBlock}>
                    <div className={styles.wrapper}>
                        <span>Полностью с&nbsp;правилами программы можно ознакомиться&nbsp;здесь</span>

                        <a href={Boolean(rules?.url) ? rules.url : "#"} className={"btn btnPrimary"}>
                            Правила программы
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}