import {Outlet, Navigate} from "react-router-dom";
import {useActor} from "@xstate/react";
import {Header} from "../modules/Header/Header";
import {Footer} from "../modules/Footer/Footer";
import {AuthState} from "../xstate/auth.machine";
import {Loader} from "../modules/Loader/Loader";

export function Account() {

    const [stateAuth, sendAuth] = useActor(AuthState);

    return (<>
        {
            (stateAuth.matches("idle") || stateAuth.matches("loading")) &&
            <div className="fullPageLoader"><Loader/></div>
        }
        {
            stateAuth.matches("unAuth") && <Navigate replace to="/auth" />
        }
        <Header account/>
        <Outlet/>
        <Footer account/>
    </>)
}