import { assign, createMachine } from "xstate";
import apiMethods from "@/js/api/apiMethods";

export const salePointMachine = createMachine(
  {
    id: "salePoint",
    context: {
      name: '',
      entity_name: '',
      region: '',
      address: '',
      owner: '',
      message: null,
      errorMessage: [],
    },
    initial: "init",
    states: {
      init: {
        on: {
          INPUT_NAME: {
            actions: ["saveName"],
            internal: true,
          },
          INPUT_ENTITY_NAME: {
            actions: ["saveEntityName"],
            internal: true,
          },
          INPUT_REGION: {
            actions: ["saveRegion"],
            internal: true,
          },
          INPUT_ADDRESS: {
            actions: ["saveAdress"],
            internal: true,
          },
          INPUT_OWNER: {
            actions: ["saveOwner"],
            internal: true,
          },
          SUBMIT_OWNER: {
            target: "sendOwner",
            actions: ["clearError"],
          },
          SUBMIT_DISTRIBUTOR: {
            target: "sendDistributor",
            actions: ["clearError"],
          },
        },
      },
      sendOwner: {
        invoke: {
          id: "saveSalePointOwner",
          src: "saveSalePointOwner",
        },
        on: {
          DONE: {
            actions: ["reset", "saveMessage"],
          },
          ERROR: {
            target: "init",
            actions: ["saveError"],
          },
        },
      },
      sendDistributor: {
        invoke: {
          id: "saveSalePointDistributor",
          src: "saveSalePointDistributor",
        },
        on: {
          DONE: {
            actions: ["reset", "saveMessage"],
          },
          ERROR: {
            target: "init",
            actions: ["saveError"],
          },
        },
      },
    },
  },
  {
    actions: {
      saveName: assign({
        name: (ctx, message) => message.data,
      }),
      saveEntityName: assign({
        entity_name: (ctx, message) => message.data,
      }),
      saveRegion: assign({
        region: (ctx, message) => message.data,
      }),
      saveAdress: assign({
        address: (ctx, message) => message.data,
      }),
      saveOwner: assign({
        owner: (ctx, message) => message.data,
      }),
      saveMessage: assign({
        message: (ctx, message) => message.data,
      }),
      saveError: assign({
        errorMessage: (ctx, message) => ctx.errorMessage.concat(message.data),
      }),
      clearError: assign({
        errorMessage: [],
      }),
      reset: assign({
        name: '',
        entity_name: '',
        region: '',
        address: '',
        owner: '',
        errorMessage: [],
      }),
      scrollTop: () => window.scrollTo(0, 0),
    },
    services: {
      saveSalePointOwner: (ctx, message) => async (send) => {
        const errors = {
          nameError: "",
          entity_nameError: "",
          regionError: "",
          addressError: "",
        };

        const fields = {
          name: ctx.name,
          entity_name: ctx.entity_name,
          region: ctx.region,
          address: ctx.address,
        };

        const fieldKeys = Object.keys(fields);

        fieldKeys.forEach((key) => {
          if (!fields[key]) {
            errors[`${key}Error`] = "Поле является обязательным для заполнения";
          }
        });

        const haveErrors = Object.values(errors).some((el) => el !== "");

        if (haveErrors) {
          send({
            type: "ERROR",
            data: errors,
          });
        } else {
          const FD = new FormData();

          fieldKeys.forEach((key) => {
            if (key !== "errorMessage") {
              FD.append(key, fields[key]);
            }
          });

          await apiMethods.owner
            .postSalePoints(FD)
            .then((response) => {
              send({
                type: "DONE",
                data: response.data.message,
              });
            })
            .catch((e) => {
              console.log("e", e);

              send({
                type: "ERROR",
                data: {
                  serverError: e.response.data.message,
                },
              });
            });
        }
      },
      saveSalePointDistributor: (ctx, message) => async (send) => {
        const errors = {
          nameError: "",
          entity_nameError: "",
          regionError: "",
          addressError: "",
          ownerError: "",
        };

        const fields = {
          name: ctx.name,
          entity_name: ctx.entity_name,
          region: ctx.region,
          address: ctx.address,
          owner: ctx.owner,
        };

        const fieldKeys = Object.keys(fields);

        fieldKeys.forEach((key) => {
          if (!fields[key]) {
            errors[`${key}Error`] = "Поле является обязательным для заполнения";
          }
        });

        const haveErrors = Object.values(errors).some((el) => el !== "");

        if (haveErrors) {
          send({
            type: "ERROR",
            data: errors,
          });
        } else {
          const FD = new FormData();

          fieldKeys.forEach((key) => {
            if (key !== "errorMessage") {
              FD.append(key, fields[key]);
            }
          });

          await apiMethods.salePoints
            .createPointDistributor(FD)
            .then((response) => {
              console.log("response", response);
              send({
                type: "DONE",
                data: response.data.message,
              });
            })
            .catch((e) => {
              console.log("e", e);

              send({
                type: "ERROR",
                data: {
                  serverError: e.response.data.message,
                },
              });
            });
        }
      },
    },
  },
);
