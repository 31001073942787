import React from 'react'
import styles from "./Table.module.scss";
import { Loader } from '@/js/modules/Loader/Loader';
import { NoData } from '@/js/modules/NoData/NoData';

export function Table(props) {
  const {
    headers,
    tableData = [],
    children,
    isLoading,
    overflow,
    remoteMehod,
    onRowClick,
    onCellClick
  } = props


  const handleRowClick = (row) => {
    if (onRowClick) onRowClick(row)
  }

  const fields = headers.map(header => header.field)

  if (!tableData.length) {
    return <NoData />
  }

  return (
    <div style={{overflow: overflow}}>
      {isLoading ? <Loader /> : (
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr className={styles.tableHead}>
              {headers.map((header) => (
                <th 
                    key={header.field} 
                    className={`${styles.headCell} ${styles.cell}`}
                >
                    {header.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            
            {
              !children && tableData.length ?
              tableData.map((row, idx) => (
                <tr 
                  className={styles.row} 
                  key={idx}
                  onClick={row.isNonClick ? null : handleRowClick(row)}
                >
                  {fields.map((field, idx) => (
                    <td
                      className={styles.cell}
                      key={idx}
                      onClick={field.isClick ?  onCellClick(field) : () => ({})}
                    >
                      {row[field]}
                    </td>
                  ))}
                </tr>
              ))
              : {children}
            }
          </tbody>
        </table>
      )}
      
    </div>
  )
}