import {assign, createMachine} from "xstate";
import apiMethods from "@/js/api/apiMethods";
import { AuthState } from "./auth.machine";
import { periodsList, typeList } from "@/js/constants";

export const ownerStatMachine = createMachine({
    id: "owner-stat",
    type: "parallel",
    context: {
        type: typeList[0], // price, liters
        period: periodsList[0], // month, 3months, 6months, year
        good: "",
        date: "",
        goods: [],
        points: [],
        pointsList: [],
        sellersList: [],
        chart_data: [],
        sellers: [],
        meta: {},
        current_seller: 0,
        current_page: 1,
        pages: undefined,
        list: [],
        id: "",
        errorMsg: []
    },
    states: {
        goods: {
            initial: "loading",
            states: {
                loading: {
                invoke: {
                    id: "getGoodsData",
                    src: "getGoodsData",
                },
                on: {
                    DONE_GOOD: {
                        actions: "saveGoodsData",
                        target: "success",
                    },
                    ERROR_GOOD: {
                        target: "failure",
                        actions: ["saveError"],
                    },
                },
            },
                success: {},
                failure: {},
            },
        },
        points: {
            initial: "loading",
            states: {
                loading: {
                invoke: {
                    id: "getPointsData",
                    src: "getPointsData",
                },
                on: {
                    DONE_POINTS: {
                        actions: "savePointsData",
                        target: "success",
                    },
                    ERROR_POINTS: {
                        target: "failure",
                        actions: ["saveError"],
                    },
                },
            },
                success: {},
                failure: {},
            },
        },
        sellers: {
            initial: "loading",
            states: {
                loading: {
                invoke: {
                    id: "getSellersData",
                    src: "getSellersData",
                },
                on: {
                    DONE_SELLERS: {
                        actions: "saveSellersData",
                        target: "success",
                    },
                    ERROR_SELLERS: {
                        target: "failure",
                        actions: ["saveError"],
                    },
                },
            },
                success: {},
                failure: {},
            },
        },
        chart: {
            initial: "loading",
            states: {
                "loading": {
                    invoke: {
                        id: "getChartData",
                        src: "getChartData"
                    },
                    on: {
                        "DONE_CHART": {
                            actions: ["saveChartData"],
                            target: "success"
                        },
                        "ERROR_CHART": {
                            actions: ["saveError"],
                            target: "failure"
                        }
                    }
                },
                "success": {
                    on: {
                        "CHANGE_FILTER": {
                            actions: ["saveChartData"],
                            target: "loading"
                        }
                    }
                },
                "failure": {}
            }
        },
        table: {
            initial: "loading",
            states: {
                "loading": {
                    invoke: {
                        id: "getTableData",
                        src: "getTableData"
                    },
                    on: {
                        "DONE_TABLE": {
                            target: "success",
                            actions: ["saveTableData"]
                        },
                        "ERROR_TABLE": {
                            target: "failure",
                            actions: ["saveError"]
                        }
                    }
                },
                "success": {
                    on: {
                        "prevPage": {
                            actions: ["changePage"],
                            target: "loading",
                            cond: "isntFirstPage"
                        },
                        "nextPage": {
                            actions: ["changePage"],
                            target: "loading",
                            cond: "isntLastPage"
                        },
                    }
                },
                "failure": {}
            }
        }
    }
}, {
    actions: {
        "saveChartData": assign({
            "period": (ctx, message) =>
                message.data.period !== undefined ? message.data.period : ctx.period,
            "date": (ctx, message) =>
                message.data.date !== undefined ? message.data.date : ctx.date,
            "type": (ctx, message) =>
                message.data.type !== undefined ? message.data.type : ctx.type,
            "good": (ctx, message) => message.data.good ?? ctx.good,
            "chart_data": (ctx, message) => message.data,
            "sellers": (ctx, message) => message.data.sellers ?? ctx.sellers,
            "points": (ctx, message) =>  message.data.points ?? ctx.points,
            "current_seller": (ctx, message) => message.data.seller ?? ctx.current_seller
        }),
        "saveTableData": assign({
            list: (ctx, message) => message.data.data.data,
            meta: (ctx, message) => message.data.data.meta,
            current_page: (ctx, message) => message.data.data.meta.currentPage,
            pages: (ctx, message) => message.data.data.meta.lastPage,
        }),
        "saveGoodsData": assign({
            goods: (ctx, message) => message.data,
        }),
        "savePointsData": assign({
            pointsList: (ctx, message) => message.data,
        }),
        "saveSellersData": assign({
            sellersList: (ctx, message) => message.data,
        }),
        "changePage": assign({
            "current_page": (ctx, message) => {
                return message.type === "nextPage" ? ctx.current_page + 1 : ctx.current_page - 1
            }
        }),
        "scrollTop": () => {
            window.scrollTo(0, 0);
        },
        "saveError": assign({
            "errorMsg": (ctx, message) => message.data.message
        })
    },
    services: {
        getGoodsData: (ctx) => {
            return async function (send) {
                try {
                    const response = await apiMethods.statistics.getGoods();
                    
                    if (response.status === 200) {
                        send({
                            type: "DONE_GOOD",
                            data: response.data.data,
                        });
                    }
                } catch (err) {
                    send({
                        type: "ERROR_GOOD",
                        data: err.response.data.message,
                    });
                }
            };
        },
        getPointsData: (ctx) => {
            return async function (send) {
                try {
                    const response = await apiMethods.salePoints.getSalePoints();
                    if (response.status === 200) {
                        send({
                            type: "DONE_POINTS",
                            data: response.data.data,
                        });
                    }
                } catch (err) {
                    send({
                        type: "ERROR_POINTS",
                        data: err.response.data.message,
                    });
                }
            };
        },
        getSellersData: (ctx) => {
            return async function (send) {
                try {
                    const response = await apiMethods.owner.getSellers();
                    if (response.status === 200) {
                        send({
                            type: "DONE_SELLERS",
                            data: response.data.data,
                        });
                    }
                } catch (err) {
                    send({
                        type: "ERROR_SELLERS",
                        data: err.response.data.message,
                    });
                }
            };
        },
        "getChartData": (ctx) => {
            return async function (send) {
                try {
                    const userType = AuthState._state.context.type;
                    const response = await apiMethods.statistics.getData(userType, {
                        period: ctx.period.value,
                        type: ctx.type.value,
                        good: ctx.good.value,
                        ...(ctx.date ? { date: ctx.date } : {}),
                        ...(ctx.points ? {points: ctx.points.map(item => item.value)} : {}),
                        ...(ctx.sellers ? {sellers: ctx.sellers.map(item => item.value)} : {})
                    });
                    
                    if (response.status === 200) {
                        send({
                            type: "DONE_CHART",
                            data: response.data.data,
                        });
                    }
                } catch (err) {
                    send({
                        type: "ERROR_CHART",
                        data: err.response.data.message,
                    });
                }
            }
        },
        "getTableData": (ctx) => {
            return async function (send) {
                await apiMethods.sales
                .getOwnerSales(10, ctx.current_page)
                .then((response) => {
                    send({
                        type: "DONE_TABLE",
                        data: response,
                    });
                })
                .catch((e) => {
                    send({
                        type: "ERROR_TABLE",
                        data: err.response.data.message,
                    });
                });
            }
        }
    },
    "guards": {
        "isntFirstPage": (ctx, message) => ctx.current_page !== 1,
        "isntLastPage": (ctx, message) => ctx.current_page !== ctx.pages,
    }
});