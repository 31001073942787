import { assign, createMachine } from "xstate";
import apiMethods from "../api/apiMethods";

export const userCardMachine = createMachine(
  {
    id: "userCard",
    initial: "requestData",
    context: {
      errors: {},
      message: "",
      userData: {},
    },
    states: {
      requestData: {
        invoke: {
          id: "getData",
          src: "getData",
        },
        on: {
          done: {
            target: "success",
            actions: ["saveData"],
          },
          error: {
            target: "failure",
            actions: ["saveError", "scrollTop"],
          },
        },
      },
      sendDataPassword: {
        invoke: {
          id: "updatePassword",
          src: "updatePassword",
        },
        on: {
          done: {
            target: "success",
          },
          error: {
            target: "failure",
            actions: ["saveError", "scrollTop"],
          },
        },
      },
      sendDataUser: {
        invoke: {
          id: "updateUserData",
          src: "updateUserData",
        },
        on: {
          done: {
            target: "requestData",
          },
          error: {
            target: "failure",
            actions: ["saveError", "scrollTop"],
          },
        },
      },
      failure: {
        on: {
          updatePassword: {
            target: "sendDataPassword",
          },
          updateUser: {
            target: "sendDataUser",
          },
        },
      },
      success: {
        on: {
          updatePassword: {
            target: "sendDataPassword",
            actions: ["resetError"],
          },
          updateUser: {
            target: "sendDataUser",
            actions: ["resetError"],
          },
        },
      },
    },
  },
  {
    actions: {
      saveData: assign({
        userData: (ctx, message) => message.data,
      }),
      saveError: assign({
        errors: (ctx, message) => message.data.errors,
        message: (ctx, message) => message.data.message,
      }),
      resetError: assign({
        message: "",
        errors: {},
      }),
      scrollTop: () => {
        /* document.getElementById("receiptsTable")?.scrollTo(0, 0); */
        window.scrollTo(0, 0);
      },
    },
    services: {
      getData: (ctx, message) => async (send) => {
        const response = await apiMethods.myInfo.getData();
        if (response.status === 200) {
          send({
            type: "done",
            data: response.data.data,
          });
        } else {
          send({
            type: "error",
            data: response,
          });
        }
      },
      updatePassword: (ctx, message) =>
        async function (send) {
          const FD = new FormData(message.data);

          await apiMethods.myInfo
            .updatePassword(FD)
            .then(() => {
              send({ type: "done" });
            })
            .catch((e) => {
              send({
                type: "error",
                data: e.response.data,
              });
            });
        },
      updateUserData: (ctx, message) =>
        async function (send) {
          await apiMethods.myInfo
            .updateUserData(message.data)
            .then(() => {
              send({ type: "done" });
            })
            .catch((e) => {
              send({
                type: "error",
                data: e.response.data,
              });
            });
        },
    },
  },
);
