import React, { useEffect, useState, useMemo } from "react";
import { useActor, useMachine } from "@xstate/react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import dayjs from "dayjs";
import { AuthState } from "@/js/xstate/auth.machine";
import styles from "./MySales.module.scss";
// import { Bonuses } from "../Bonuses/Bonuses";
import { Banners } from "../Banners/Banners";
import { mySalesMachine } from "../../xstate/mysales.machine";
import { ChartSelect } from "../ChartSelect/ChartSelect";
import { Pagination } from "../Pagination/Pagination";
import { Icon } from "../SVGsprite/svgsprite";
import { Loader } from "../Loader/Loader";
import { ChartArea } from "../ChartArea/ChartArea";
import { periodsList, typeList } from "../../constants";
import { NoData } from "../NoData/NoData";
import { Table } from "@/js/modules/Table/Table";
import { sellerSalesColumns } from "@/js/constants";

export function MySales() {
  const [stateAuth, sendAuth] = useActor(AuthState);
  const [stateMySales, sendMySales] = useMachine(mySalesMachine);
  const { meta, list } = stateMySales.context;

  useEffect(() => {
    document.title = "Мои продажи | Программа лояльности SK ZIC";
  }, []);

  const goodsOptions = () => {
    if (stateMySales.context.goods && stateMySales.context.goods.length) {
      return stateMySales.context.goods.map((item) => ({
          value: item.name,
          label: item.name,
        }));
    }

    return [];
  };

  const handleCLick = (event) => {
    if (stateMySales.context.period === "week") return;
    const keys = {
      six_month: "month",
      month: "week",
    };

    const barIndex = event.target.getAttribute('index');

    sendMySales({
      type: "CHANGE_FILTER",
      data: {
        period: keys[stateMySales.context.period],
        ...(barIndex >= 0 ? { date: stateMySales.context.chart_data[barIndex].date } : {})
      },
    });
  };

  return (
    <>
      <section className={styles.workarea}>
        <div className="titleBlock">
          <h1>Мои продажи</h1>
          <div className={styles.row}>
            {!["owner", "distributor"].includes(stateAuth.context.type) && (
              <Link to="/account/upload" className="btn btnPrimary btnIcon">
                <Icon name="icon-add" sizeW="24" sizeH="24" />
                Загрузить чек
              </Link>
            )}
            <a
              href={stateMySales.context.report}
              className="btn btnPrimary"
            >
              Скачать отчет
            </a>
          </div>
        </div>

        <section className={clsx("statContainer", styles.chartArea)}>
          <div className={styles.titleBlock}>
            <h2>График продаж, сумма продаж</h2>

            <div className={styles.chartControls}>
              <ChartSelect
                send={sendMySales}
                type="CHANGE_FILTER"
                dataName="type"
                label="Выбор типа"
                name="typeSelect"
                defaultValue="price"
                options={[...typeList]}
              />
              <ChartSelect
                send={sendMySales}
                value={stateMySales.context.period}
                type="CHANGE_FILTER"
                dataName="period"
                label="Выбор периода"
                name="periodSelect"
                defaultValue="week"
                options={[...periodsList]}
              />
              <ChartSelect
                send={sendMySales}
                type="CHANGE_FILTER"
                dataName="good"
                label="Выбор товара"
                name="goodSelect"
                defaultValue="''"
                options={[
                  {
                    value: "",
                    label: "Все товары ZIC",
                  },
                  ...goodsOptions(),
                ]}
              />
            </div>
          </div>

          {stateMySales.matches({ chart: "loading" }) ? (
            <Loader />
          ) : (
            <ChartArea
              onClick={handleCLick}
              chart_data={stateMySales.context.chart_data}
            />
          )}
        </section>

        <section className="statContainer contentContainer">
          <h2 className="visuallyHidden">Таблица продаж</h2>

          {list.length > 0 ? (
            <SalesTable
              list={list}
              meta={meta}
              state={stateMySales}
              action={sendMySales}
            />
          ) : (
            <NoData />
          )}
        </section>
      </section>
      {(stateAuth.context.type === "seller" ||
        stateAuth.context.type === "owner") && (
        <aside className={styles.sidebar}>
          {/* <Bonuses/> */}
          <Banners />
        </aside>
      )}
    </>
  );
}

function SalesTable(props) {
  const { list } = props.list;

  const prepareBodyData = useMemo(() => props.list.reduce((acc, cur) => {
      acc.push({
        date: dayjs(cur.purchase_at).format("DD.MM.YYYY, HH:mm"),
        good: cur.good,
        quantity: cur.quantity,
        liters: cur.liters,
        sum: new Intl.NumberFormat("ru-RU").format(cur.sum),
        points: new Intl.NumberFormat("ru-RU").format(cur.points),
      });
      return acc;
    }, []), [props.list]);

  return (
    <>
      <Table
        headers={sellerSalesColumns}
        tableData={prepareBodyData}
        isLoading={props.state.matches("loading")}
      />
      {list &&
      list.length &&
      props.meta.lastPage > 1 &&
      !props.state.matches("loading") ? (
        <Pagination
          pages={props.state.context.pages}
          current_page={props.state.context.current_page}
          send={props.action}
        />
      ) : (
        <></>
      )}
    </>
  );
}
