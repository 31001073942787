import {useActor, useMachine} from "@xstate/react";
import {cardMachine} from "../../xstate/card.machine";
import {AuthState} from "../../xstate/auth.machine";
import {useEffect} from "react";
import {Dialog, DialogBackdrop} from "reakit";
import clsx from "clsx";
import styles from "./PrizeCard.module.scss";
import {Icon} from "../SVGsprite/svgsprite";

export function CardModal(props) {

    let [stateCard, sendCard, serviceCard] = useMachine(cardMachine);
    let [stateAuth, sendAuth] = useActor(AuthState);

    useEffect(() => {
        sendCard("resetting")
    }, [props.dialog.visible])

    return (
        <DialogBackdrop {...props.dialog} className={"backdrop"}>
            <Dialog
                {...props.dialog}
                aria-label={`Информация о призе ${props.data.name}`}
                className={clsx("modal", styles.modalCard)}
            >
                <div className="modalHead">
                    <button className={"modalClose"} onClick={() => {
                        sendCard({type: "resetting"});
                        props.dialog.hide();
                    }}>
                        <Icon name={"icon-close"} sizeW="32" sizeH="32"/>
                        <span className="visuallyHidden">
                            Закрыть
                        </span>
                    </button>
                </div>
                <div className="modalBody">
                    {
                        stateCard.matches("show_promocode")
                            ? <ShowPromocode
                                service={serviceCard}
                                data={props.data}
                            />
                            : <div className={styles.cardBody}>
                                <div className={styles.cardInfo}>
                                    <h2>
                                        {props.data.name}
                                    </h2>
                                    <div className={styles.cardPrice}>
                                        {new Intl.NumberFormat('ru-RU').format(props.data.points)} баллов
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: props.data.description}} className={styles.cardDescription}>
                                    </div>

                                    <hr/>
                                    {
                                        (stateCard.context.errorMessage.length > 0) &&
                                        <div className={"errorMessage"}>
                                            {stateCard.context.errorMessage}
                                        </div>
                                    }

                                    {
                                        stateAuth.matches("auth")
                                            ? <div className={styles.cardButtons}>
                                                <button
                                                    onClick={
                                                        () =>
                                                            sendCard({type: "purchase", data: props.data.id})
                                                    }
                                                    className="btn btnPrimary btnSmall"
                                                >
                                                    Заказать за {new Intl.NumberFormat('ru-RU').format(props.data.points)} баллов
                                                </button>
                                                <button
                                                    className="btn btnSmall btnContour btnGhost"
                                                    onClick={() => {
                                                        props.dialog.hide();
                                                        sendCard({type: "resetting"})
                                                    }}
                                                >
                                                    Отмена
                                                    <span className="visuallyHidden">
                                                        Закрыть модальное окно
                                                    </span>
                                                </button>
                                            </div>
                                            :
                                            <div className={"errorMessage"}>Авторизуйтесь, чтобы приобрести этот приз</div>
                                    }
                                </div>
                                <div className={styles.cardImage}>
                                    <img src={props.data.image} alt=""/>
                                </div>
                            </div>
                    }
                </div>
                <div className="modalFooter">
                    {  }
                    {/* <div className={styles.howToBuy}>
                        <h3>
                            Как заказать
                        </h3>
                        <div className={styles.cardInstructions}
                             dangerouslySetInnerHTML={{__html: props.data.instruction}}/>
                    </div> */}
                </div>
            </Dialog>
        </DialogBackdrop>
    )
}

function ShowPromocode(props) {

    let [stateCard, sendCard] = useActor(props.service);

    console.log(props)

    return (
        <div className={styles.showPromocode}>
            <h2>
                Покупка успешно совершена!
            </h2>
            <p>
                Вы успешно приобрели промокод на покупку товара &laquo;{props.data.name}&raquo;
            </p>
            <p className={styles.flow}>
                С&nbsp;вашего бонусного счёта списано {new Intl.NumberFormat('ru-RU').format(props.data.points)}&nbsp;баллов.
            </p>
            {/*<div className={styles.promocodeBlock}>*/}
            {/*    <h3 className={"note"}>Промокод на&nbsp;товар</h3>*/}
            {/*    <div className={styles.promocodeDisplay}>*/}
            {/*        {stateCard.context.promocode}*/}
            {/*    </div>*/}
            {/*    <button*/}
            {/*        className={"btn btnPrimary btnSmall"}*/}
            {/*        type={"button"}*/}
            {/*        onClick={*/}
            {/*            () => {*/}
            {/*                navigator.clipboard.writeText(stateCard.context.promocode).then();*/}
            {/*            }*/}
            {/*        }*/}
            {/*    >*/}
            {/*        Скопировать<span className={"visuallyHidden"}>промокод</span>*/}
            {/*    </button>*/}
            {/*</div>*/}
        </div>
    )
}
