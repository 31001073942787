import {useEffect} from "react";

export function Page404(){

    useEffect(() => {
        document.title = "Страница не найдена | Программа лояльности SK ZIC";
    }, []);

    return (<>
        <h1>Страница не найдена</h1>
    </>)
}