import { useEffect } from "react";
import { useActor } from "@xstate/react";
import { Navigate } from "react-router-dom";
import { Header } from "../modules/Header/Header";
import { Footer } from "../modules/Footer/Footer";
import { AuthForm } from "../modules/AuthForm/AuthForm";
import { AuthState } from "../xstate/auth.machine";
import { Loader } from "../modules/Loader/Loader";

export function Auth() {
  const [stateAuth] = useActor(AuthState);

  useEffect(() => {
    document.title = "Авторизация | Программа лояльности SK ZIC";
  }, []);

  return (
    <>
      {(stateAuth.matches("idle") && !stateAuth.context.name) && (
        <div className="fullPageLoader">
          <Loader />
        </div>
      )}
      {stateAuth.matches("auth") && <Navigate replace to="/account" />}
      <Header />
      <main id="main" role="main">
        <AuthForm />
      </main>
      <Footer />
    </>
  );
}
