import { useEffect } from "react";
import {Link, useParams} from "react-router-dom";
import {useMachine} from "@xstate/react";
import clsx from "clsx";
import Select from 'react-select';
import {ownerStatMachine} from "@/js/xstate/ownerstat.machine";
import {Pagination} from "../Pagination/Pagination";
import {NoData} from "../NoData/NoData";
// import {ChartSelect} from "../ChartSelect/ChartSelect";
import styles from "./ShopStat.module.scss";
import { periodsList, typeList } from "@/js/constants";
import {Loader} from "../Loader/Loader";
import {ChartArea} from "../ChartArea/ChartArea";


export function ShopStat () {
    const {shopID = ""} = useParams();
    const [stateShop, sendShop] = useMachine(ownerStatMachine, {
        context: {
            ...ownerStatMachine.context,
            id: shopID.replace(/shop/g, "")
        }
    });

    const { meta, list } = stateShop.context;

    const goodsOptions = () => {
        if (stateShop.context.goods && stateShop.context.goods.length) {
        return stateShop.context.goods.map((item) => ({
                value: item.name,
                label: item.name,
            }));
        }

        return [];
    };

    const handleCLick = (event) => {
        if (stateShop.context.period.value === 'week') return
        const keys = {
            six_month: {
                label: "Месяц",
                value: "month"
            },
            month: {
                label: "Неделя (по дням)",
                value: "week"
            },
        }

        const barIndex = event.target.getAttribute('index');

        sendShop({
            "type": "CHANGE_FILTER",
            "data": {
                "period": keys[stateShop.context.period.value],
                ...(barIndex >= 0 ? { date: stateShop.context.chart_data[barIndex].date } : {})
            }
        })
    }

    const sellersOptions = () => {
        if (stateShop.context.sellersList && stateShop.context.sellersList.length) {
            return stateShop.context.sellersList.map((item) => ({
                    value: item.id,
                    label: `${item.name} ${item.surname}`,
                }));
        }

        return [];
    };

    const pointsOptions = () => {
        if (stateShop.context.pointsList && stateShop.context.pointsList.length) {
            return stateShop.context.pointsList.map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
        }

        return [];
    };

    useEffect(() => {
        document.title = "Статистика | Программа лояльности SK ZIC";
    }, []);

    const handleChange = (val, options) => {
        sendShop({
            type: options.type,
            data: {
                [options.name]: val
            }
        })
    }

    return (
        <>
            <h1>Статистика магазина</h1>

            {
                stateShop.context.errorMsg.length > 0 &&
                stateShop.context.errorMsg.map((item, index)=><div className="errorMessage" key={index}>
                        {item}
                    </div>)
            }

            <section className={clsx("statContainer", styles.chartArea)}>
                <div className={styles.titleBlock}>
                    <h2>График продаж, сумма продаж</h2>

                    <div className={styles.chartControls}>
                        <Select
                            name="type"
                            placeholder="Выбор типа"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    minHeight: 24,
                                    // height: 24,
                                    width: 250
                            }), input: (baseStyle, state) => ({
                                ...baseStyle,
                                // height: 24
                            }), indicatorSeparator: (baseStyle, state) => ({
                                display: 'none'
                            })}}
                            defaultValue={typeList[0]}
                            value={stateShop.context.type}
                            options={[...typeList]}
                            onChange={(value) => handleChange(value, {type: "CHANGE_FILTER", name: "type"})}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                        <Select
                            name="period"
                            placeholder="Выбор периода"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    minHeight: 24,
                                    // height: 24,
                                    width: 250
                            }), input: (baseStyle, state) => ({
                                ...baseStyle,
                                // height: 24
                            }), indicatorSeparator: (baseStyle, state) => ({
                                display: 'none'
                            })}}
                            value={stateShop.context.period}
                            options={[...periodsList]}
                            onChange={(value) => handleChange(value, {type: "CHANGE_FILTER", name: "period"})}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                        <Select
                            name="sellers"
                            placeholder="Выбор товара"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    minHeight: 24,
                                    // height: 24,
                                    width: 250
                            }), input: (baseStyle, state) => ({
                                ...baseStyle,
                                // height: 24
                            }), indicatorSeparator: (baseStyle, state) => ({
                                display: 'none'
                            })}}
                            value={stateShop.context.good}
                            options={[{
                                    value: '',
                                    label: "Все товары ZIC",
                                },...goodsOptions()]}
                            onChange={(value) => handleChange(value, {type: "CHANGE_FILTER", name: "good"})}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                        <Select
                            isMulti
                            name="sellers"
                            placeholder="Все продавцы ZIC"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    minHeight: 24,
                                    // height: 24,
                                    width: 250
                            }), input: (baseStyle, state) => ({
                                ...baseStyle,
                                // height: 24
                            }), indicatorSeparator: (baseStyle, state) => ({
                                display: 'none'
                            })}}
                            value={stateShop.context.sellers}
                            options={[...sellersOptions()]}
                            onChange={(value) => handleChange(value, {type: "CHANGE_FILTER", name: "sellers"})}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                        <Select
                            isMulti
                            name="points"
                            placeholder="Все товарные точки ZIC"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    minHeight: 24,
                                    // height: 24,
                                    width: 250
                            }), input: (baseStyle, state) => ({
                                ...baseStyle,
                                // height: 24
                            }), indicatorSeparator: (baseStyle, state) => ({
                                display: 'none'
                            })}}
                            value={stateShop.context.points}
                            options={[...pointsOptions()]}
                            onChange={(value) => handleChange(value, {type: "CHANGE_FILTER", name: "points"})}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                </div>
                {
                    stateShop.matches({chart: "loading"})
                        ? <Loader/>
                        : <ChartArea
                            onClick={handleCLick}
                            chart_data={stateShop.context.chart_data}
                        />
                }
            </section>

            <section className="statContainer">
                <h2 className="visuallyHidden">Таблица продаж</h2>

                {
                    stateShop.context.list.length > 0
                        ? <>
                            <SellersTable
                                data={stateShop.context.list}
                            />
                            {
                                stateShop.context.pages > 1 &&
                                <Pagination
                                    pages={stateShop.context.pages}
                                    current_page={stateShop.context.current_page}
                                    send={sendShop}
                                />
                            }
                        </>
                        : <NoData/>
                }
            </section>
        </>
    )
}

function SellersTable (props) {
    if (props.data.length === 0) {
        return <NoData/>
    } 
        return <div className="scrollbox">
            <table id="distrTable" className="statTable">
                <thead>
                <tr>
                    <th>Торговая точка</th>
                    <th>Продавцы</th>
                    <th>Продано ед.</th>
                    <th>Кол-во литров</th>
                    <th>Сумма продаж</th>
                    <th>Кол-во заработанных баллов</th>
                    <th>Кол-во исп. баллов</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.data.map(item => <tr key={item.id}>
                            <td className="noWhiteSpace">
                                {item.point}
                            </td>
                            <td className={styles.itemName}>
                                {/* <Link to={`seller${item.id}`}> */}
                                    {item.seller}
                                {/* </Link> */}
                            </td>
                            <td className="noWhiteSpace">
                                {item.quantity}
                            </td>
                            <td className="noWhiteSpace">
                                {item.liters}
                            </td>
                            <td className="noWhiteSpace">
                                {new Intl.NumberFormat('ru-RU').format(item.sum)} &#8381;
                            </td>
                            <td className="noWhiteSpace">
                                {new Intl.NumberFormat('ru-RU').format(item.deposit_points)}
                            </td>
                            <td className="noWhiteSpace">
                                {new Intl.NumberFormat('ru-RU').format(item.draw_points)}
                            </td>
                        </tr>)
                }
                </tbody>
            </table>
        </div>
    
}