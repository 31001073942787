import { assign, createMachine } from "xstate";
import apiMethods from "@/js/api/apiMethods";
import { AuthState } from "./auth.machine";

/* Продажи одного продавца */
export const mySalesMachine = createMachine(
  {
    id: "mySales",
    type: "parallel",
    context: {
      type: "price", // price, liters
      period: "week", // week, month, six_month
      date: "",
      good: "",
      goods: [],
      chart_data: [],
      current_page: 1,
      meta: {},
      pages: undefined,
      list: [],
      id: "",
      errorMsg: [],
      report: "",
    },
    states: {
      report: {
        initial: "loading",
        states: {
          loading: {
            invoke: {
              id: "getReportData",
              src: "getReportData",
            },
            on: {
              DONE_REPORT: {
                actions: "saveReportData",
                target: "success",
              },
              ERROR_REPORT: {
                target: "failure",
                actions: ["saveError"],
              },
            },
          },
          success: {},
          failure: {},
        },
      },
      goods: {
        initial: "loading",
        states: {
          loading: {
            invoke: {
              id: "getGoodsData",
              src: "getGoodsData",
            },
            on: {
              DONE_GOOD: {
                actions: "saveGoodsData",
                target: "success",
              },
              ERROR_GOOD: {
                target: "failure",
                actions: ["saveError"],
              },
            },
          },
          success: {},
          failure: {},
        },
      },
      chart: {
        initial: "loading",
        states: {
          loading: {
            invoke: {
              id: "getChartData",
              src: "getChartData",
            },
            on: {
              DONE_CHART: {
                actions: "saveChartData",
                target: "success",
              },
              ERROR_CHART: {
                target: "failure",
                actions: ["saveError"],
              },
            },
          },
          success: {
            on: {
              CHANGE_FILTER: {
                actions: "saveChartData",
                target: "loading",
              },
            },
          },
          failure: {},
        },
      },
      table: {
        initial: "loading",
        states: {
          loading: {
            invoke: {
              id: "getTableData",
              src: "getTableData",
            },
            on: {
              DONE_TABLE: {
                target: "success",
                actions: ["saveTableData"],
              },
              ERROR_TABLE: {
                target: "failure",
                actions: ["saveError"],
              },
            },
          },
          success: {
            entry: "scrollTop",
            on: {
              prevPage: {
                actions: ["changePage"],
                target: "loading",
                cond: "isntFirstPage",
              },
              nextPage: {
                actions: ["changePage"],
                target: "loading",
                cond: "isntLastPage",
              },
            },
          },
          failure: {},
        },
      },
    },
  },
  {
    actions: {
      saveChartData: assign({
        period: (ctx, message) =>
          message.data.period !== undefined ? message.data.period : ctx.period,
        date: (ctx, message) => message.data?.date !== undefined ? message.data?.date : ctx?.date,
        type: (ctx, message) =>
          message.data.type !== undefined ? message.data.type : ctx.type,
        good: (ctx, message) =>
          message.data.good !== undefined ? message.data.good : ctx.good,
        chart_data: (ctx, message) => message.data,
      }),
      saveGoodsData: assign({
        goods: (ctx, message) => message.data,
      }),
      saveReportData: assign({
        report: (ctx, message) => message.data,
      }),
      saveTableData: assign({
        list: (ctx, message) => message.data.data.data,
        meta: (ctx, message) => message.data.data.meta,
        current_page: (ctx, message) => message.data.data.meta.currentPage,
        pages: (ctx, message) => message.data.data.meta.lastPage,
      }),
      changePage: assign({
        current_page: (ctx, message) => message.type === "nextPage"
            ? ctx.current_page + 1
            : ctx.current_page - 1,
      }),
      scrollTop: () => {
        window.scrollTo(0, 0);
      },
      saveError: assign({
        errorMsg: (ctx, message) => message.data.message,
      }),
    },
    services: {
      getReportData: (ctx) => async function (send) {
          try {
            const response = await apiMethods.sales.getReportSales();

            if (response.status === 200) {
              send({
                type: "DONE_REPORT",
                data: response.data.link,
              });
            }
          } catch (err) {
            console.log(err);
            send({
              type: "ERROR_REPORT",
              data: err.response.data.message,
            });
          }
        },
      getGoodsData: (ctx) => async function (send) {
          try {
            const response = await apiMethods.statistics.getGoods();
            if (response.status === 200) {
              send({
                type: "DONE_GOOD",
                data: response.data.data,
              });
            }
          } catch (err) {
            send({
              type: "ERROR_GOOD",
              data: err.response.data.message,
            });
          }
        },
      getChartData: (ctx) => async function (send) {
          try {
            const userType = AuthState._state.context.type;

            const response = await apiMethods.statistics.getData(userType, {
              period: ctx.period,
              type: ctx.type,
              good: ctx.good,
              ...(ctx.date?.length ? { date: ctx.date } : {})
            });

            if (response.status === 200) {
              send({
                type: "DONE_CHART",
                data: response.data.data,
              });
            }
          } catch (err) {
            send({
              type: "ERROR_CHART",
              data: err.response.data.message,
            });
          }
        },
      getTableData: (ctx, message) =>
        async function (send) {
          await apiMethods.sales
            .getSellerSales(10, ctx.current_page)
            .then((response) => {
              send({
                type: "DONE_TABLE",
                data: response,
              });
            })
            .catch((e) => {
              send({
                type: "ERROR_TABLE",
                data: e,
              });
            });
        },
    },
    guards: {
      isntFirstPage: (ctx, message) => ctx.current_page !== 1,
      isntLastPage: (ctx, message) => ctx.current_page !== ctx.pages,
    },
  },
);
