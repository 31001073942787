import axios from "axios";

const env = import.meta.env;

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
export const api = axios.create({
  baseURL: `${env.VITE_APP_API_URL}/api`,
  headers: headers,
});

api.interceptors.request.use((config) => {
  let token = localStorage.getItem("sessionID");

  if (token && config.headers) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});
