import {Disclosure, DisclosureContent, useDisclosureState} from "reakit";
import clsx from "clsx";
import styles from "./Accordion.module.scss"

export function AccordionItem(props) {
    const disclosure = useDisclosureState();
    
    return <article className={clsx(props.className, styles.accordionItem)}>
        <Disclosure {...disclosure} className={styles.accordionTrigger}>
            {props.title}
        </Disclosure>
        <DisclosureContent {...disclosure} className={styles.accordionPanel}>
            <div dangerouslySetInnerHTML={{__html: props.content}} />
        </DisclosureContent>
    </article>
}