import {useCallback, useEffect, useState} from "react";
import {Loader} from "../Loader/Loader";
import clsx from "clsx";
import styles from "./Banners.module.scss";
import {Link} from "react-router-dom";
import apiMethods from "../../api/apiMethods";

export function Banners() {
    let [loading, setLoading] = useState(false);
    let [banners, setBanners] = useState([]);

    const fetchBanners = useCallback(async () => {
        setLoading(true);
        const response = await apiMethods.banners.getBanners();
        setBanners(response.data.data);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchBanners();
    }, [fetchBanners]);

    if (banners.length > 0) {
        return (
            <>
                {
                    loading
                        ? <Loader/>
                        : <>
                            {
                                banners.map((item, idx) => {
                                    return <article
                                        key={idx}
                                        className={clsx(styles.announce, styles.news)}
                                    >
                                        <h2>{item.title}</h2>
                                        {
                                            item.image &&
                                            <img
                                                className={styles.cover}
                                                src={item.image}
                                                alt=""
                                            />
                                        }
                                        <p>{item.description}</p>
                                        {
                                            item.button &&
                                            <a
                                                href={item.button.link}
                                                className={"btn btnPrimary btnSmall"}
                                            >
                                                {item.button.text}
                                            </a>
                                        }
                                    </article>
                                })
                            }
                        </>
                }
            </>
        )
    } else {
        return null
    }
}