import styles from "./Prizes.module.scss";
import {Link} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {Loader} from "../Loader/Loader";
import {PrizeCard} from "../PrizeCard/PrizeCard";
import apiMethods from "../../api/apiMethods";

/* Список призов на главной */

export function Prizes() {

    let [list, setList] = useState([]);
    let [loading, setLoading] = useState(false);

    const fetchShopData = useCallback( async () => {
        setLoading(true);
        try {
            const response = await apiMethods.bonuses.getGifts() //await apiMethods.shop.getMainData();
            setList(response.data.data.slice(0, 5));

        } catch ({response}) {
            console.log(response.data.message);
        }
        ;
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchShopData();
    }, [fetchShopData]);

    return (
        <section className={styles.prizes}>
            <div className="wrapper">
                <h2 className="line">
                    Призы
                </h2>

                <ul className={styles.cardsGroup}>
                    {
                        loading
                            ? <Loader/>
                            : <>{
                                (list.length > 0) &&
                                list.map(item => {
                                    return (
                                        <li key={`prize${item.id}`}>
                                            <PrizeCard {...item} />
                                        </li>
                                    )
                                })
                            }</>
                    }
                </ul>
                <Link to={"/shop"} className={"btn btnPrimary"}>
                    Смотреть все призы
                </Link>
            </div>
        </section>
    )
}