import apiUrls from '@/js/api/urls';

export default function (api) {
    return {
      createPointDistributor(data) {
        return api.post(apiUrls.SALE_POINT_DISTRIBUTOR, data)
      },
      getRegions(){
        return api.get(apiUrls.REGIONS)
      },
      getSalePoints() {
        return api.get(apiUrls.SALE_POINTS_ALL)
      },
      getPointsDistributor(params) {
        const { perPage, currentPage, status } = params
        let query = `?perPage=${perPage}&currentPage=${currentPage}`
        
        if (status) {
          query += `&status=${status}`
        }

        return api.get(`${apiUrls.SALE_POINT_DISTRIBUTOR}${query}`)
      },
      getPointsOwner(params) {
        const { perPage, currentPage } = params
        const query = `?perPage=${perPage}&currentPage=${currentPage}`

        return api.get(`${apiUrls.SALE_POINT_OWNER}${query}`)
      },
      createPointOwner(data) {
        return api.post(apiUrls.SALE_POINT_OWNER, data)
      },
      editSalePoint(id, data) {
        return api.post(`${apiUrls.SALE_POINT_DISTRIBUTOR}/${id}`, data)
      },
      changeStatus(id, type) {
        return api.post(`${apiUrls.SALE_POINT_DISTRIBUTOR}/${id}/${type}`)
      },
      getPointInfo(id) {
        return api.get(`${apiUrls.SALE_POINT_DISTRIBUTOR}/${id}`)
      }
    }
};