import apiUrls from '../urls';

export default function (api) {
    return {
        postData(data) {
            return api.post(apiUrls.UPLOAD_VOUCHER, data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
        },
    }
};
