import {useEffect} from "react";
import {useActor, useMachine} from "@xstate/react";
import {Link} from "react-router-dom";
import {myGiftsMachine} from "../../xstate/mygifts.machine";
import styles from "./MyGifts.module.scss";
import {Bonuses} from "../Bonuses/Bonuses";
import {Banners} from "../Banners/Banners";
import {Icon} from "../SVGsprite/svgsprite";
import {Loader} from "../Loader/Loader";
import {Pagination} from "../Pagination/Pagination";
import {NoData} from "../NoData/NoData";
import {AuthState} from "../../xstate/auth.machine";
import copyImg from '@/assets/copy.png'

export function MyGifts() {
    const [stateAuth] = useActor(AuthState);
    const [stateGifts, sendGifts] = useMachine(myGiftsMachine);

    useEffect(() => {
        document.title = "Мои подарки | Программа лояльности SK ZIC";
    }, []);

    return (<>
            <section className={styles.workarea}>
                <div className="titleBlock">
                    <h1>Мои подарки</h1>
                    {
                        stateAuth.context.can_upload && !['owner', 'distributor'].includes(stateAuth.context.type) &&
                        <Link to="/account/upload" className="btn btnPrimary btnIcon">
                            <Icon name="icon-add" sizeW="24" sizeH="24"/>
                            Загрузить чек
                        </Link>
                    }
                </div>
                <div className="statContainer">
                    {
                        stateGifts.matches("loading")
                            ? <Loader/>
                            : <>
                                <GiftsTable data={stateGifts.context.list}/>
                                {
                                    stateGifts.context.pages > 1 &&
                                    <Pagination
                                        pages={stateGifts.context.pages}
                                        current_page={stateGifts.context.current_page}
                                        send={sendGifts}
                                    />
                                }
                            </>
                    }
                </div>
            </section>
            <aside className={styles.sidebar}>
                {/* <Bonuses/> */}
                <Banners/>
            </aside>
        </>
    )
}

function GiftsTable(props) {
    if (props.data.length === 0) {
        return <NoData/>
    }

    return (
        <div className="scrollbox">
            <table className="statTable">
                <thead>
                <tr>
                    <th>Дата оформления</th>
                    <th>Фото</th>
                    <th>Наименование приза</th>
                    <th>Приз</th>
                    <th>Баллы</th>
                    <th>Статус приза</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.data.map((item, idx) => <tr key={idx}>
                            <td>{new Date(item.created_at).toLocaleString()}</td>
                            <td>
                                <img className={styles.giftImage} src={item.prize.image} alt={item.prize.name}/>
                            </td>
                            <td className={styles.name}>
                                {item.prize.name}
                            </td>
                            <td>
                                <div className={styles.promocode}>
                                    { item.type.slug === 'promotional_code' && item.certificate.code ? 
                                        <div
                                            className={styles.copy}
                                            onClick={
                                                () => {
                                                    navigator.clipboard.writeText(item.certificate.code).then();
                                                }
                                            }
                                        >
                                            {
                                                item.certificate.code
                                            }
                                            <img src={copyImg} />
                                        </div> : item.type.slug === 'certificate' && item.certificate.file ? <a href={item.certificate.file} download target="__blank">Скачать</a> : <></>
                                    }
                                    
                                </div>
                            </td>
                            <td className={styles.flow}>
                                -{new Intl.NumberFormat('ru-RU').format(item.points)} баллов
                            </td>
                            <td>
                                {item.status.i18n}
                            </td>
                        </tr>)
                }
                </tbody>
            </table>
        </div>
    )
}