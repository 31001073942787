import { useState, useEffect } from 'react'
import { useMachine } from "@xstate/react"
import { FormTemplate } from "../../FormTemplate/FormTemplate";
import { ownerRegisterMachine } from "@/js/xstate/ownerRegister.machine";
import { SuccessPopup } from "@/js/modules/SuccessPopup/SuccessPopup";

export function OwnerRegisterForm() {
  const [stateOwner, sendOwner] = useMachine(ownerRegisterMachine)
  const [isSuccessPopup, setSuccessPopup] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    sendOwner({ type: "SUBMIT" })
  }

  useEffect(() => {
    setSuccessPopup(Boolean(stateOwner.context.message));
  }, [stateOwner.context.message]);

  return (
    <FormTemplate title='Зарегистрировать Владельца' isLoading={false}>
      {stateOwner.context.errorMessage.map(
        (item) =>
          item.serverError &&
          item.serverError !== "" && (
            <div className="errorMessage" key="errName">
              {item.serverError}
            </div>
          ),
      )}
      
      <form onSubmit={handleSubmit}
        >
          <div className="formRow">
              <label htmlFor="name">
                Имя
              </label>
              <input
                  id="name"
                  type="text"
                  name="name"
                  value={stateOwner.context.name}
                  onChange={(event) => {
                    sendOwner({
                      type: "INPUT_NAME",
                      key: "name",
                      data: event.target.value
                    })
                  }}
              />
              {
                stateOwner.context.errorMessage.map(item=> item.nameError && item.nameError !== "" && <div className="errorMessage" key="errName">
                    {item.nameError}
                  </div>)
              }
          </div>
          <div className="formRow">
              <label htmlFor="surname">
                Фамилия
              </label>
              <input
                  id="surname"
                  type="text"
                  name="surname"
                  value={stateOwner.context.surname}
                  onChange={(event) => {
                    sendOwner({
                      type: "INPUT_SURNAME",
                      key: 'surname',
                      data: event.target.value
                    })
                  }}
              />
              {
                stateOwner.context.errorMessage.map(item=> item.surnameError && item.surnameError !== "" && <div className="errorMessage" key="errSurname">
                    {item.surnameError}
                  </div>)
              }
          </div>
          <div className="formRow">
              <label htmlFor="email">
                E-mail
              </label>
              <input
                  id="email"
                  type="text"
                  name="email"
                  value={stateOwner.context.email}
                  onChange={(event) => {
                    sendOwner({
                      type: "INPUT_EMAIL",
                      key: "email",
                      data: event.target.value
                    })
                  }}
              />
              {
                stateOwner.context.errorMessage.map(item=> item.emailError && item.emailError !== "" && <div className="errorMessage" key="errEmail">
                    {item.emailError}
                  </div>)
              }
          </div>
          <div className="formRow">
              <label htmlFor="phone">
                  Телефон
              </label>
              <input
                  id="phone"
                  type="text"
                  name="phone"
                  value={stateOwner.context.phone}
                  onChange={(event) => {
                    sendOwner({
                      type: "INPUT_PHONE",
                      key: 'phone',
                      data: event.target.value
                    })
                  }}
              />
              {
                stateOwner.context.errorMessage.map(item=> item.phoneError && item.phoneError !== "" && <div className="errorMessage" key="errPhone">
                    {item.phoneError}
                  </div>)
              }
          </div>
          <div className="formRow">
            <button type="submit" className="btn btnPrimary">
              Зарегистрировать
            </button>
          </div>
        </form>
        <SuccessPopup
          isOpen={isSuccessPopup}
          onClose={() => setSuccessPopup(false)}
          title={stateOwner.context.message}
        />
    </FormTemplate>
  )
}