import styles from "./About.module.scss"
import {Icon} from "../SVGsprite/svgsprite";

export function About() {
    return (<section className={styles.about}>
        <div className="wrapper">
            <div className={styles.aboutContainer}>
                <div className={styles.textSection}>
                    <h2 className={"line"}>
                        Что такое программа <br/> лояльности?
                    </h2>
                    <p>Программа лояльности SK&nbsp;ZIC позволит вам получать ценные подарки за&nbsp;ваши продажи
                        товаров SK&nbsp;ZIC.</p>

                    <p>Всё что вам нужно &mdash; это продавать товары участвующие в&nbsp;программе и&nbsp;сканировать
                        чеки. За&nbsp;это вы&nbsp;будете получать баллы, которые в&nbsp;свою очередь вы&nbsp;сможете
                        обменять на&nbsp;призы.</p>

                    <p>Чем больше товаров продано, тем&nbsp;больше баллов вы&nbsp;получите и&nbsp;тем ценнее подарки
                        сможете получить</p>
                </div>
                <div className={styles.imageSection}>
                    <img src="/images/zic-bottle.png"
                         alt="Бутылка моторного масла ZIC Top в окружении золотистых монет"/>
                </div>
            </div>
            <div className={styles.features}>
                <article className={styles.featureCard}>
                    <div className={styles.featureIcon}>
                        <Icon name={"icon-cart"} sizeH="33" sizeW="33" />
                    </div>
                    <h3>
                        Продавайте <br/><span>товары ZIC</span>
                    </h3>
                </article>
                <article className={styles.featureCard}>
                    <div className={styles.featureIcon}>
                        <Icon name={"icon-scanner"} sizeH="33" sizeW="33" />
                    </div>
                    <h3>
                        Сканируйте <br/><span>чеки</span>
                    </h3>

                </article>
                <article className={styles.featureCard}>
                    <div className={styles.featureIcon}>
                        <Icon name={"icon-coins"} sizeH="33" sizeW="33" />
                    </div>
                    <h3>
                        Копите <br/><span>баллы</span>
                    </h3>

                </article>
                <article className={styles.featureCard}>
                    <div className={styles.featureIcon}>
                        <Icon name={"icon-star"} sizeH="33" sizeW="33" />
                    </div>
                    <h3>
                        Обменивайте <br/><span>баллы на&nbsp;ценные призы</span>
                    </h3>

                </article>

            </div>
        </div>
    </section>)
}