import { assign, createMachine } from "xstate";
import apiMethods from "@/js/api/apiMethods";

export const salePointsMachine = createMachine(
  {
    id: "salePoints",
    context: {
      list: [],
      current_page: 1,
      pages: undefined,
      meta: {},
      errorMsg: [],
    },
    initial: "loading",
    states: {
      loading: {
        invoke: {
          id: "getData",
          src: "getData",
        },
        on: {
          done: {
            target: "success",
            actions: ["saveData"],
          },
          error: {
            target: "failure",
            actions: ["setError"],
          },
        },
        entry: "scrollTop",
      },
      success: {
        on: {
          prevPage: {
            actions: ["changePage"],
            target: "loading",
            cond: "isntFirstPage",
          },
          nextPage: {
            actions: ["changePage"],
            target: "loading",
            cond: "isntLastPage",
          },
        },
      },
      failure: {
        on: {
          retry: {
            target: "loading",
          },
        },
      },
    },
  },
  {
    actions: {
      saveData: assign({
        list: (ctx, message) => message.data.data,
        current_page: (ctx, message) => message.data.meta.currentPage,
        pages: (ctx, message) => message.data.meta.lastPage,
        meta: (ctx, message) => message.data.meta,
      }),
      changePage: assign({
        current_page: (ctx, message) => {
          return message.type === "nextPage"
            ? ctx.current_page + 1
            : ctx.current_page - 1;
        },
      }),
      setError: assign((ctx, message) => message.data),
      scrollTop: () => {
        /*document.getElementById("receiptsTable")?.scrollTo(0, 0);*/
        window.scrollTo(0, 0);
      },
    },
    services: {
      getData: (ctx, message) => {
        return async function (send) {
          const response = await apiMethods.owner.getSalePoints(
            10,
            ctx.current_page,
          );

          if (response.status === 200) {
            send({
              type: "done",
              data: response.data,
            });
          } else {
            send({
              type: "error",
              data: response.data,
            });
          }
        };
      },
    },
    guards: {
      isntFirstPage: (ctx, message) => ctx.current_page !== 1,
      isntLastPage: (ctx, message) => ctx.current_page !== ctx.pages,
    },
  },
);
