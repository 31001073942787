import apiMethods from "@/js/api/apiMethods";
import { assign, createMachine } from "xstate";

/* Транзакции продавца */
export const myTransactionsMachine = createMachine(
  {
    id: "myTransactions",
    context: {
      current_page: 1,
      meta: {},
      pages: undefined,
      list: [],
      errorMsg: [],
    },

    initial: "loading",
    states: {
      loading: {
        invoke: {
          id: "getTableData",
          src: "getTableData",
        },
        on: {
          DONE_TABLE: {
            target: "success",
            actions: ["saveTableData"],
          },
          ERROR_TABLE: {
            target: "failure",
            actions: ["saveError"],
          },
        },
      },
      success: {
        entry: "scrollTop",
        on: {
          prevPage: {
            actions: ["changePage"],
            target: "loading",
            cond: "isntFirstPage",
          },
          nextPage: {
            actions: ["changePage"],
            target: "loading",
            cond: "isntLastPage",
          },
        },
      },
      failure: {},
    },
  },
  {
    actions: {
      saveTableData: assign({
        list: (ctx, message) => message.data.data.data,
        // meta: (ctx, message) => message.data.data.meta,
        // current_page: (ctx, message) => message.data.data.meta.currentPage,
        // pages: (ctx, message) => message.data.data.meta.lastPage,
      }),
      changePage: assign({
        current_page: (ctx, message) => {
          return message.type === "nextPage"
            ? ctx.current_page + 1
            : ctx.current_page - 1;
        },
      }),
      scrollTop: () => {
        window.scrollTo(0, 0);
      },
      saveError: assign({
        // errorMsg: (ctx, message) => message.data.message,
      }),
    },
    services: {
      getTableData: (ctx, message) =>
        async function (send) {
          await apiMethods.transactions
            .getSellerTransactions(10, ctx.current_page)
            .then((response) => {
              send({
                type: "DONE_TABLE",
                data: response,
              });
            })
            .catch((e) => {
              console.log(e)
              send({
                type: "ERROR_TABLE",
                data: e,
              });
            });
        },
    },
    guards: {
      isntFirstPage: (ctx, message) => ctx.current_page !== 1,
      isntLastPage: (ctx, message) => ctx.current_page !== ctx.pages,
    },
  },
);
