import apiUrls from '@/js/api/urls';

export default function (api) {
  return {
    /** получение всех владельцев
     * @name getData
     * @request POST /references/owners
     * @param params @type {
     *  limit: number
     *  search: string
     *  orderBy: {
     *    field: id | name | surname,
     *    value: string[] | number[]
     *    direction: asc | desc
     *  }
     * }
     */ 
    getData(params = {}) {
      let searchString = ''
      let requestUrl = ''
      const keys = Object.keys(params)
      if (keys.length) {
        keys.forEach((key, idx) => {
          if (params.key && idx === 0) {
            searchString = `?${key}=${params[key]}`
          } else if (key === 'orderBy') {
            if (!searchString.length) {
              searchString = `?${key}[${params[key].field}]=${JSON.stringify(params[key].value)}`
            } else {
              searchString += `&${key}[${params[key].field}]=${JSON.stringify(params[key].value)}`
            }

            if (params[key].direction && searchString.length) {
              searchString += `&${key}['direction']=[${params[key].direction}]`
            } else if (params[key].direction && !searchString.length) {
              searchString += `?${key}['direction']=[${params[key].direction}]`
            }
          } else if (!searchString.length) {
            searchString += `?${key}=${params[key]}`
          } else {
            searchString += `&${key}=${params[key]}`
          }
        })
        requestUrl = `${apiUrls.OWNERS}${searchString}`
      } else {
        requestUrl = apiUrls.OWNERS
      }

      return api.get(requestUrl);
    },
    /** Создание владельца для distributor
     * @name create
     * @request POST /distributor/owners
     * @param data @type {
     *  name: string
     *  surname: string
     *  phone: string
     *  email: string
     * }
     */
    create(data) {
      return api.post(apiUrls.DISTRIBUTOR_OWNERS, data)
    },
    /**
     * @name getDistributorOwners
     * @request /GET /distributor/owners
     * @param {*} params @type {
     *  perPage: number
     *  currentPage: number
     * }
     */
    getDistributorOwners({ perPage, currentPage }) {
      return api.get(`${apiUrls.DISTRIBUTOR_OWNERS}?perPage=${perPage}&currentPage=${currentPage}`)
    }
  }
};