import {assign, createMachine} from "xstate";
import apiMethods from "@/js/api/apiMethods";

export const ownerRegisterMachine = createMachine({
  id: 'ownerRegister',
  context: {
    name: '',
    surname: '',
    phone: '',
    email: '',
    message: null,
    errorMessage: []
  },
  initial: "init",
  states: {
    init: {
      on: {
        INPUT_NAME: {
          actions: ['saveName'],
          internal: true
        },
        INPUT_SURNAME: {
          actions: ['saveSurname'],
          internal: true
        },
        INPUT_PHONE: {
          actions: ['savePhone'],
          internal: true
        },
        INPUT_EMAIL: {
          actions: ['saveEmail'],
          internal: true
        },
        SUBMIT: {
          target: "send",
          actions: ["clearError"]
        }
      }
    },
    send: {
      invoke: {
        id: "saveOwner",
        src: "saveOwner"
      },
      on: {
        DONE: {
          target: "init",
          actions: ["reset", "saveMessage"]
        },
        ERROR: {
          target: "init",
          actions: ["saveError"]
        }
      }
    },
  }
}, {
  actions: {
    saveName: assign({
      name: (ctx, message) => message.data
    }),
    saveSurname: assign({
      surname: (ctx, message) => message.data 
    }),
    savePhone: assign({
      phone: (ctx, message) => message.data
    }),
    saveEmail: assign({
      email: (ctx, message) => message.data
    }),
    saveError: assign({
      errorMessage: (ctx, message) => {
        return ctx.errorMessage.concat(message.data)
      }
    }),
    saveMessage: assign({
      message: (ctx, message) => message.data,
    }),
    clearError: assign({
      errorMessage: []
    }),
    reset: assign({
      name: '',
      surname: '',
      phone: '',
      email: '',
      errorMessage: []
    }),
    "scrollTop": () => window.scrollTo(0, 0)
  },
  services: {
    saveOwner: (ctx, message) => async (send) => {
      const errors = {
        nameError: "",
        surnameError: "",
        phoneError: "",
        emailError: "",
      }
      const emailRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const phoneRegExp = /\+7\d{3}\d{3}\d{2}\d{2}/g

      const fieldKeys = Object.keys(ctx).filter(key => !['message', 'errorMessage'].includes(key))

      fieldKeys.forEach(key => {
        if (!ctx[key]) {
          errors[`${key}Error`] = 'Поле является обязательным для заполнения'
        } else {
          if (key === 'phone' && !phoneRegExp.test(ctx[key])) {
            errors[`${key}Error`] = 'Некорректный телефон. Введите +7XXXXXXXXXX'
          } else if (key === 'email' && !emailRegExp.test(ctx[key])) {
            errors[`${key}Error`] = 'Некорректный email'
          }
        }
      })

      const haveErrors = Object.values(errors).some(el => el !== "");
      if(haveErrors) {
        send({
          type: "ERROR",
          data: errors
        })
      } else {
        const FD = new FormData()

        fieldKeys.forEach(key => {
          if (!['errorMessage', 'messageError'].includes(key)) {
            FD.append(key, ctx[key])
          }
        })
        
          await apiMethods.owners.create(FD)
          .then((response) => {
              send({
                type: "DONE",
                data: response.data.message
              })
            })
            .catch((e) => {
              const errorKeys = Object.keys(e.response.data.errors)
              const errors = errorKeys.map(key => {
                const error = {}
                error[`${key}Error`] = e.response.data.errors[key][0]

                return error
              })

              send({
                type: "ERROR",
                data: errors
              })
            });
      }
    }
  }
})