import {assign, createMachine} from "xstate";
import apiMethods from "../api/apiMethods";

let nextIndex = 0;

function getID() {
    return nextIndex++
}

export const feedbackMachine = createMachine({
    id: "feedback",
    context: {
        previews: [],
        files: [],
        email: "",
        message: "",
        errorMessage: []
    },
    initial: "init",
    states: {
        "init": {
            on: {
                "INPUT_EMAIL": {
                    actions: ["saveEmail"],
                    internal: true
                },
                "INPUT_MESSAGE": {
                    actions: ["saveMessage"],
                    internal: true
                },
                "INPUT_FILES": {
                    actions: ["saveFiles"],
                    internal: true
                },
                "DELETE_FILE": {
                    internal: true,
                    actions: ["deleteFile"]
                },
                "SUBMIT": {
                    target: "send",
                    actions: ["clearError"]
                }
            }
        },
        "send": {
            invoke: {
                id: "upload",
                src: "upload"
            },
            on: {
                "DONE": {
                    target: "success",
                    actions: ["reset"],
                },
                "ERROR": {
                    target: "init",
                    actions: ["saveError"]
                }
            }
        },
        "success": {
            on: {
                "ONE_MORE": {
                    target: "init"
                }
            }
        }
    }
}, {
    actions: {
        "saveFiles": assign({
            "files": (ctx, message) => {

                const newFiles = Array.from(message.data, (item) => ({
                        "id": getID(),
                        "file": item,
                        "preview": URL.createObjectURL(item)
                    }));
                return ctx.files.concat(newFiles);
            }
        }),
        "saveEmail": assign({
            "email": (ctx, message) => message.data
        }),
        "saveMessage": assign({
            "message": (ctx, message) => message.data
        }),
        "saveError": assign({
            "errorMessage": (ctx, message) => ctx.errorMessage.concat(message.data)
        }),
        "clearError": assign({
            "errorMessage": []
        }),
        "deleteFile": assign({
            "files": (ctx, message) => {
                const newList = ctx.files.filter((item) => item.id !== message.data)
                return newList
            }
        }),
        "reset": assign({
            "previews": [],
            "files": [],
            "email": "",
            "message": "",
            "errorMessage": []
        }),
        "scrollTop": () => window.scrollTo(0, 0,)
    },
    services: {
        "upload": (ctx, message) => async function (send) {

                const emailRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                const errors = {
                    "emailError": "",
                    "messageError": ""
                };

                if (ctx.email === "") {
                    errors.emailError = "Поле email обязательно для заполнения"
                } else if (!emailRegExp.test(ctx.email)) {
                    errors.emailError = "Некорректный email"
                }

                if (ctx.message === "") {
                    errors.messageError = "Поле сообщение обязательно для заполнения"
                }

                const haveErrors = Object.values(errors).some(el => el !== "");

                if (haveErrors) {
                    send({
                        type: "ERROR",
                        data: errors
                    })
                } else {
                    const FD = new FormData();
                    
                    for (let i = 0; i < ctx.files.length; i++) {
                        FD.append('attachments[]', ctx.files[i].file);
                    }
                    
                    // ctx.files.forEach(item => FD.append("attachments[]", item.file));
                    FD.append("email", ctx.email);
                    FD.append("text", ctx.message);
                    
                    const response = await apiMethods.feedback.sendFeedback(FD)
                    
                    if (response.status === 200) {
                        send({
                            type: "DONE"
                        })
                    } else {
                        send({
                            type: "ERROR",
                            data: {
                                "serverError": response.message
                            }
                        })
                    }
                }
            }

    }
});