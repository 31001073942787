import styles from "./Showcase.module.scss";

import {MoreBonuses} from "../MoreBonuses/MoreBonuses";
import {useActor} from "@xstate/react";
import {PrizeCard} from "../PrizeCard/PrizeCard";


export function Showcase(props) {
    let [state] = useActor(props.ShopState);

    let firstBunch = state.context.list.slice(0, 12),
        secondBunch = state.context.list.slice(12);
        
    return (<>
        <section className={styles.topCardsSection}>
            <div className="wrapper">

                {
                    firstBunch.length > 0
                        ? <>
                            <h2 className={"visuallyHidden"}>Начало списка подарков</h2>
                            <ul className={styles.cardsGroup}>
                                {
                                    firstBunch.map(item => {
                                        return <li key={`prize${item.id}`}><PrizeCard {...item} /></li>
                                    })
                                }
                            </ul>
                        </>
                        : <section aria-live={"polite"} className={styles.noMatch}>
                            По вашему запросу ничего не найдено
                        </section>
                }
            </div>
        </section>
        {/* <MoreBonuses/> */}

        {
            secondBunch.length > 0 &&
            <div className="wrapper">
                <h2 className={"visuallyHidden"}>Продожения списка подарков</h2>
                <ul className={styles.cardsGroup}>
                    {
                        secondBunch.map(item => {
                            return <li key={`prize${item.id}`}><PrizeCard {...item} /></li>
                        })
                    }
                </ul>
            </div>
        }
    </>)
}