import apiUrls from '../urls';

export default function (api) {
    return {
        sendFeedback(data) {
            return api.post(apiUrls.FEEDBACK, data, {
              headers: {
                "Accept": "application/json",
                "Content-Type": "multipart/form-data"
              },
            });
        },
    }
};