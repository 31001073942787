import { api } from "./api";
import authModule from "./authApi/authModule";
import bannersModule from "./bannersApi/bannersModule";
import docsModule from "./docsApi/docsModule";
import faqModule from "./faqModule/faqModule";
import moreBonusesModule from "./moreBonusesApi/moreBonusesModule";
import myGiftsModule from "./myGiftsApi/myGiftsModule";
import myInfoModule from "./myInfoApi/myInfoModule";
import uploadVoucherModule from "./uploadVoucherApi/uploadVoucherModule";
import myReceiptsModule from "./myRecieptsApi/myReceiptsModule";
import ownerModule from "./ownerApi/ownerModule";
import rulesModule from "./rulesApi/rulesModule";
import shopModule from "./shopApi/shopModule";
import feedbackModule from "./feedbackApi/feedbackApi";
import salePointApi from "./salePointApi/salePointApi";
import ownersModule from "./ownersApi/ownersApi";
import statisticsModule from "./statisticsApi/statisticsApi";
import salesModule from "./salesApi/salesModule";
import transactionsModule from "./transactionsApi/transactionsModule";

export default {
  authentication: authModule(api),
  myInfo: myInfoModule(api),
  uploadVoucher: uploadVoucherModule(api),
  myReceipts: myReceiptsModule(api),
  banners: bannersModule(api),
  bonuses: moreBonusesModule(api),
  myGifts: myGiftsModule(api),
  shop: shopModule(api),
  docs: docsModule(api),
  feedback: feedbackModule(api),
  faq: faqModule(api),
  rules: rulesModule(api),
  owners: ownersModule(api),
  statistics: statisticsModule(api),
  salePoints: salePointApi(api),
  owner: ownerModule(api),
  sales: salesModule(api),
  transactions: transactionsModule(api),
};
