import { useState } from "react";

import {Dialog, DialogBackdrop} from "reakit";
import clsx from "clsx";
import styles from "./BannerModal.module.scss";
// import { Link } from 'react-router-dom';
import { Icon } from "../SVGsprite/svgsprite";

import {Loader} from "../Loader/Loader";


export function BannersModal(props) {
    const {banner} = props
    const {loading} = props


    return (
        <DialogBackdrop {...props.dialog} className="backdrop">
            <Dialog
                {...props.dialog}
                aria-label="Как зарегистрироваться?"
                className={clsx("modal", styles.bannerModal)}
            >
                <div className={clsx("modalHead modalCardOneChild", styles.bannerModalHead)}>
                    <button type="button" className="modalClose" style={{marginLeft: 'auto'}} onClick={() => {
                        props.dialog.hide();
                    }}>
                        <Icon name="icon-close" sizeW="32" sizeH="32"/>
                        <span className="visuallyHidden">
                            Закрыть
                        </span>
                    </button>
                </div>

                <div className={clsx("modalBody", styles.bannerBody)}>
                  {
                    loading
                        ? <Loader/>
                        : 
                          <article
                                className={clsx(styles.announce, styles.news)}
                            >
                                <h2 className={styles.title}>{banner.title}</h2>
                                {
                                    banner.image &&
                                    <img
                                        className={styles.cover}
                                        src={banner.image}
                                        alt=""
                                    />
                                }
                                <p>{banner.description}</p>
                                {
                                    banner.button &&
                                    <a
                                        href={banner.button.link}
                                        className="btn btnPrimary btnSmall"
                                    >
                                        {banner.button.text}
                                    </a>
                                }
                            </article>
                }
                </div>
            </Dialog>
        </DialogBackdrop>
    )
}