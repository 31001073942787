import clsx from "clsx";
import {Link} from "react-router-dom";
import {useActor} from "@xstate/react";
import {AuthState} from "../../xstate/auth.machine";
import styles from "./Hero.module.scss";


export function Hero() {
    const [authState] = useActor(AuthState);

    return (
        <section className={styles.hero}>
            <div className={clsx("wrapper", styles.wrapper)}>
                <div className={styles.textSection}>
                    <h1 className="line">Получайте подарки <br/>
                        за баллы от продаж!</h1>
                    <p>
                        Продавайте товары ZIC, копите баллы <br/>
                        и&nbsp;получайте взамен ценные призы.
                    </p>
                    {/* authState.context.type === "distributor" || authState.context.type === "owner"  */}
                    {!['owner', 'distributor'].includes(authState.context.type) && (
                        <Link
                            className="btn btnPrimary"
                            to={
                                AuthState.state.matches("auth")
                                    ? "/account/upload"
                                    : "/auth"
                            }
                        >
                            Загрузить чек
                        </Link>
                    )} 
                </div>
                <div className={styles.imageSection}>
                    <img src="/images/hero-img.png" alt=""/>
                </div>
            </div>
        </section>
    )
}