import apiUrls from '../urls';

export default function (api) {
    return {
        getData(ctx, defaultCtx) {
            let url = '';
            switch (true) {
                case ctx.category !== defaultCtx.category:
                    url = `${apiUrls.SHOP}?category=${ctx.category}`;
                    break;
                case ctx.current_page !== defaultCtx.current_page:
                    url = `${apiUrls.SHOP}?page=${ctx.current_page}`;
                    break;
                case ctx.sort !== defaultCtx.sort:
                    url = `${apiUrls.SHOP}?sort=${ctx.sort}`;
                    break;
                case ctx.available !== defaultCtx.available:
                    url = `${apiUrls.SHOP}?available=${ctx.available}`;
                    break;
                case ctx.can_buy !== defaultCtx.can_buy:
                    url = `${apiUrls.SHOP}?can_buy=${ctx.can_buy}`;
                    break;
                case ctx.query !== defaultCtx.query:
                    url = `${apiUrls.SHOP}?query=${ctx.query}`;
                    break;
                default:
                    url = apiUrls.SHOP
                    break;
            }
            return api.get(url);
        },
        getMainData() {
            return api.get(apiUrls.SHOP_MAIN);
        }

    }
};