import { useEffect } from "react";
import { useMachine } from "@xstate/react";
import dayjs from "dayjs";
import styles from "./MyTransactions.module.scss";
import { Loader } from "../Loader/Loader";
import { Pagination } from "../Pagination/Pagination";
import { Banners } from "../Banners/Banners";
import { NoData } from "../NoData/NoData";
import { myTransactionsMachine } from "../../xstate/mytransactions.machine";

export function MyTransactions() {
  const [stateTransactions, sendTransactions] = useMachine(
    myTransactionsMachine,
  );

  useEffect(() => {
    document.title = "Мои баллы | Программа лояльности SK ZIC";
  }, []);

  return (
    <>
      <section className={styles.workarea}>
        <div className="titleBlock">
          <h1>Мои баллы</h1>
        </div>

        <div className="statContainer">
          {stateTransactions.matches("loading") ? (
            <Loader />
          ) : (
            <>
              <ReceiptsTable data={stateTransactions.context.list} />
              {stateTransactions.context.meta.pages > 1 && (
                <Pagination
                  pages={stateTransactions.context.pages}
                  current_page={stateTransactions.context.current_page}
                  send={sendTransactions}
                />
              )}
            </>
          )}
        </div>
      </section>
      <aside className={styles.sidebar}>
        {/* <Bonuses/> */}
        <Banners />
      </aside>
    </>
  );
}

function ReceiptsTable(props) {
  if (props.data.length === 0) {
    return <NoData />;
  }
  console.log(props.data);
  return (
    <div className="scrollbox">
      <table className="statTable">
        <thead>
          <tr>
            <th>Дата и время начисления/списания баллов</th>
            <th>За что начислено /списано</th>
            <th>Количество баллов</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((item) => (
            <tr key={item.id}>
              <td>{dayjs(item.created_at).format("DD.MM.YY, HH:mm")}</td>
              <td>{item.description}</td>
              <td>{item.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
