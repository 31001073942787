import styles from "./MoreBonuses.module.scss";
import {useCallback, useEffect, useState} from "react";
import clsx from "clsx";
import apiMethods from "../../api/apiMethods";
import { Banners } from "@/js/modules/Banners/Banners";

export function MoreBonuses() {
    let [data, setData] = useState({});
    // const fetchBonuses = useCallback( async () => {
    //     const response = await apiMethods.bonuses.getGifts();
        
    //     if (response.status === 200) {
    //         setData(response.data.data);
    //     }
    // }, []);

    // useEffect(() => {
    //     fetchBonuses();
    // }, [fetchBonuses]);

    return (
        <section className={styles.moreBonuses}>
            <div className={clsx("wrapper", styles.wrapper)}>
                <div className={styles.textSection}>
                    <h2>Получите повышенные баллы</h2>
                    {/* <p>
                        {data.text}
                    </p>
                    <ul className={styles.itemsList}>
                        {
                            data?.items?.map((elem, index) => {
                                return (
                                    <li key={`el${index}`}>
                                        {elem.name}
                                    </li>
                                )
                            })
                        }
                    </ul> */}
                    <div className={styles.bannersWrapper}>
                        <Banners />
                    </div>
                </div>
                {/* <div className={styles.imageSection}>
                    <img src={data?.image} alt=""/>
                </div> */}
            </div>
        </section>
    )
}