import React, { useState, useCallback } from 'react'
import { Table } from '@/js/modules/Table/Table'
import {Pagination} from "@/js/modules/Pagination/Pagination";
import apiMethods from '@/js/api/apiMethods';
import { SuccessPopup } from "@/js/modules/SuccessPopup/SuccessPopup";
import {Icon} from "@/js/modules/SVGsprite/svgsprite";


export function TableSalePointsNew(props) {
  const {
    columns,
    statePoints,
    meta,
    onSend,
  } = props

  const [isSuccessPopup, setSuccessPopup] = useState(false);
  const [message, setMessage] = useState('')

  const actions = [{
    status: 'active',
    type: 'approve'
  }, {
    status: 'inactive',
    type: 'reject'
  }]

  const handleClickStatus = useCallback(async (item) => {
    try {
      const response = await apiMethods.salePoints.changeStatus(item.id, item.action)
      
      if (response.status === 200) {
        onSend({
          type: "REFETCH"
        })
        setSuccessPopup(true)
        setMessage(response.data.message)
      }
    } catch (err) {
      throw new Error(err)
    }
  }, [])


  const tableDataNew = statePoints.context.list.map(item => ({
    name: item.name,
    owner: item.owner,
    entity_name: item.entity_name,
    region: item.region,
    address: item.address,
    actions: <div>
      {actions.map(action => 
        <button 
          onClick={() => handleClickStatus({id: item.id, action: action.type})} 
          key={action.type}>
            <Icon name={`icon-${action.status === 'active' ? 'check-circle' : 'cross-circle'}`} sizeW="32" sizeH="32"/>
          </button>)}
    </div>
  }))


  return (
    <>
      <Table 
        overflow="auto"
        headers={columns}
        tableData={tableDataNew}
        isLoading={statePoints.matches("loading")}
      />
      {
        statePoints.context.list.length && meta.lastPage > 1 && !statePoints.matches("loading") ?
        <Pagination 
          pages={statePoints.context.pages}
          current_page={statePoints.context.current_page}
          send={onSend}
        /> : <></>
      }

      <SuccessPopup
        isOpen={isSuccessPopup}
        onClose={() => setSuccessPopup(false)}
        title={message}
        // text={stateSalePoint.context.message}
      />
    </>
  )
}