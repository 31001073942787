import { useCallback, useEffect, useState } from "react";
import { FormTemplate } from "../../FormTemplate/FormTemplate";
import apiMethods from "../../../../api/apiMethods";
import { patterns, validationErrorMessages } from "../../../../constants";
import { useForm } from "react-hook-form";
import { SuccessPopup } from "../../../SuccessPopup/SuccessPopup";
import { useParams } from 'react-router-dom'

const getSalePointsOptions = (data) => {
    return data.map(item => ({name: item.name, value: item.id}));
}

const parsePhone = (value) => {
    if (isNaN(value) || value === ' ' || value === '') {
        return ""
    }
    return parseInt(value);
}

const PHONE_LENGTH = 10;
const title = 'Редактирование продавца';

export function EditSellerForm() {
    const { id } = useParams()
    
    const [ loading, setLoading ] = useState(false);
    const [salePointOptions, setSailPointOptions] = useState([]);
    const [statuses, setStatuses] = useState([])
    const { register, handleSubmit, setValue, setError, formState: { errors } } = useForm();
    const [successMessage, setSuccessMessage] = useState(null);
    const [seller, setSeller] = useState({})
    const fetchSalingPoints = useCallback( async () => {
        setLoading(true);
        try {
            const {data} = await apiMethods.owner.getSalePoints();
            setSailPointOptions(getSalePointsOptions(data.data))
            setLoading(false);
        } catch ({response}) {
            setLoading(false);
        }    
    }, []);

    const fetchStatuses = useCallback(async () => {
        try {
            const { data } = await apiMethods.owner.getStatuses();
            if (data.data) {
                const options = data.data.map(item => ({
                    name: item.i18n, 
                    value: item.slug
                }))
                setStatuses(options)
            }
        } catch (err) {

        }
    }, [])

    const fetchSeller = useCallback(async () => {
        try {
            const { data } = await apiMethods.owner.getSeller(id);
            if (data.data) {
                setSeller(data.data)
            }
        } catch (err) {

        }
    }, [id])

    useEffect(() => {
        document.title = `${title} | Программа лояльности SK ZIC`;
        fetchSeller()
    }, []);

    useEffect(() => {
        fetchSalingPoints();
        fetchStatuses()
    }, [fetchSalingPoints]);

    const fetchFormData = useCallback(async(body) => {
        try {
            const {data} = await apiMethods.owner.editSeller(id, body);
            setSuccessMessage(data.message);
        } catch ({response}) {
            if (response.status === 422) {
                Object.entries(response.data.errors).forEach(([key, val]) => {
                    setError(key, { type: 'custom', message: val[0]})
            } )
            }
        }
    })

    const submit = data => {
        fetchFormData(data);
    }


    return (
            <FormTemplate title='Редактировать данные' isLoading={false}>
                {
                    <form 
                        onSubmit={handleSubmit(submit)}
                        noValidate
                    >
                        <div className="formRow">
                            <label htmlFor="name">
                                Имя
                            </label>
                            <input
                                id="name"
                                type="text"
                                name="name"
                                disabled
                                value={seller.name}
                                onChange={(event) => {
                                }}
                            />
                            {/* {
                                stateFeedback.context.errorMessage.map(item=> {
                                    return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                                        {item.emailError}
                                    </div>
                                })
                            } */}
                        </div>
                        <div className="formRow">
                            <label htmlFor="surname">
                                Фамилия
                            </label>
                            <input
                                id="surname"
                                type="text"
                                name="surname"
                                disabled
                                value={seller.surname}
                                onChange={(event) => {
                                }}
                            />
                            {/* {
                                stateFeedback.context.errorMessage.map(item=> {
                                    return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                                        {item.emailError}
                                    </div>
                                })
                            } */}
                        </div>
                        <div className="formRow">
                            <label htmlFor="email">
                                E-mail
                            </label>
                            <input
                                id="email"
                                type="email"
                                name="email"
                                disabled
                                value={seller.email}
                                onChange={(event) => {
                                }}
                            />
                            {/* {
                                stateFeedback.context.errorMessage.map(item=> {
                                    return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                                        {item.emailError}
                                    </div>
                                })
                            } */}
                        </div>
                        <div className="formRow">
                            <label htmlFor="salePoint">
                                Торговая точка
                            </label>
                            <select
                                className="select"
                                id="sale_point"
                                type="text"
                                name="sale_point"
                                {...register("sale_point", { required: validationErrorMessages.REQUIRED })}
                            >
                            {
                                Boolean(errors.sale_point?.message) && 
                                    <div className={"errorMessage"}>
                                        {errors.sale_point.message }
                                    </div>
                            }
                                {
                                    salePointOptions?.length > 0 &&
                                    salePointOptions.map(option => 
                                    <option key={option.value} value={option.value}>{option.name}</option>)
                                }
                            </select>
                        </div>
                        <div className="formRow">
                            <label htmlFor="status">
                                Статус
                            </label>
                            <select
                                className="select"
                                id="status"
                                type="text"
                                name="status"
                                {...register("status", { required: validationErrorMessages.REQUIRED })}
                            >
                            {
                                Boolean(errors.status?.message) && 
                                    <div className={"errorMessage"}>
                                        {errors.status.message }
                                    </div>
                            }
                                {
                                    statuses?.length > 0 &&
                                    statuses.map(option => 
                                    <option key={option.value} value={option.value}>{option.name}</option>)
                                }
                            </select>
                        </div>
                        <div className="formRow">
                            <button disabled={!id || errors?.length} type={"submit"} className={"btn btnPrimary"}>
                                Сохранить
                            </button>
                        </div>

                        <SuccessPopup
                            isOpen={Boolean(successMessage)}
                            onClose={() => setSuccessMessage('')} 
                            title={'Продавец успешно обновлен'}
                            text={successMessage}
                        />
                    </form>
                }

            </FormTemplate>
    )
}