import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import styles from './CustomModal.module.scss';
import clsx from "clsx";

const ANIMATION_TIMEOUT = 250;

export const CustomModal = ({
    children,
    isOpen,
    onClose,
    className = '',
}) => {
    const [isClosing, setIsClosing] = useState(false);
    const [isOpening, setIsOpening] = useState(false);

    const timerRef = useRef();

    const closeHandler = useCallback(() => {
        setIsOpening(false);
        setIsClosing(true);
        timerRef.current = setTimeout(() => {
            onClose?.();
            setIsClosing(false);
        }, ANIMATION_TIMEOUT);
    }, [onClose]);

    useEffect(() => {
        const onKeyDown = (evt) => {
            if (evt.key === 'Escape') {
                closeHandler();
            }
        };
        if (isOpen) {
            timerRef.current = setTimeout(() => {
                setIsOpening(true);
            }, 0);
            window.addEventListener('keydown', onKeyDown);
        }
        return () => {
            clearTimeout(timerRef.current);
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [isOpen, closeHandler]);

    const onContentClick = (evt) => {
        evt.stopPropagation();
    };

    const mods = useMemo(() => ({
        [styles.opened]: isOpen,
        [styles.isClosing]: isClosing,
        [styles.isOpening]: isOpening,
    }), [isOpen, isClosing, isOpening]);

    return (isOpen
        ? (
            <div className={clsx(styles.modal, mods, [className])}>
                <div className={styles.overlay} onClick={closeHandler}>

                    <div
                        className={clsx(styles.content)}
                        onClick={onContentClick}
                    >
                        {children}
                    </div>

                </div>
            </div>
        )
        : null
    );
};
