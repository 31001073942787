import { Loader } from "../../Loader/Loader";
import styles from "./FormTemplate.module.scss";
import clsx from "clsx";

export function FormTemplate({children, title, isLoading}) {

    return (
        <section className={clsx(styles.form)}>
            <div className={styles.wrapper}>
                {
                   isLoading && <div className={"fullPageLoader"}><Loader/></div>
                }

                <h1>{title}</h1>
                {children}
            </div>
        </section>
    )
}