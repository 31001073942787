import {DialogDisclosure, useDialogState} from "reakit";
import clsx from "clsx";
import {useActor} from "@xstate/react";
import {CardModal} from "./CardModal";
import styles from "./PrizeCard.module.scss";
import {AuthState} from "@/js/xstate/auth.machine";


export function PrizeCard(props) {
    const [authState] = useActor(AuthState);
    const dialog = useDialogState({animated: true});

    return (
        <article className={styles.card}>
            <div className={styles.cardInfo}>
                <h3 className={styles.cardName}>
                    {props.name}
                </h3>
                <div className={styles.cardPrice}>
                    {new Intl.NumberFormat('ru-RU').format(props.points)} баллов
                </div>
            </div>
            <div className={styles.cardImage}>
                <img src={props.image} alt={props.name}/>
            </div>
            {!['owner', 'distributor'].includes(authState.context.type) && (
                <DialogDisclosure
                    {...dialog}
                    className={clsx("btn btnSmall btnContour", styles.purchaseBtn)}
                >
                    Заказать <span className="visuallyHidden">{props.name}</span>
                </DialogDisclosure>
            )}
            <CardModal
                dialog={dialog}
                data={props}
            />
        </article>
    )
}

