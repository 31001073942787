import apiUrls from '../urls';

export default function (api) {
    return {
        auth(data) {
            return api.post(apiUrls.LOGIN, data);
        },
        check() {
            return api.get(apiUrls.INIT);
        },
        user() {
            return api.get(apiUrls.PERSONAL_FULL);
        },
        restore(data) {
            return api.post(apiUrls.RESTORE_PASSWORD, data);
        },
        logout() {
            return api.post(apiUrls.LOGOUT)
        },
    }
};